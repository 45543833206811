// src/pages/Logout.js
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../authContext"; // Import context

const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext); // Use context logout

  useEffect(() => {
    const logoutUser = async () => {
      try {
        await logout(); // Use context logout
        navigate("/login", { replace: true });
      } catch (error) {
        console.error("❌ Logout Error:", error);
        navigate("/login", { replace: true }); // Navigate even if logout fails
      }
    };

    logoutUser();
  }, [navigate, logout]);

  return null;
};

export default Logout;