import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Typography, Select, MenuItem, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Card, CardContent, Tabs, Tab, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Slider from '@mui/material/Slider';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AcUnitIcon from '@mui/icons-material/AcUnit';

// Helper function to determine position placement
const getAvailablePosition = (player, currentLineup, site) => {
  const positions = player.position.split('/');
  const dkPositions = ['PG', 'SG', 'SF', 'PF', 'C', 'G', 'F', 'UTIL'];
  const fdPositions = ['PG_1', 'PG_2', 'SG_1', 'SG_2', 'SF_1', 'SF_2', 'PF_1', 'PF_2', 'C'];
  const availablePositions = site === 'dk' ? dkPositions : fdPositions;

  for (const pos of positions) {
    for (const slot of availablePositions) {
      if (!currentLineup[slot] && (
        slot === pos || 
        (slot === 'G' && ['PG', 'SG'].includes(pos)) || 
        (slot === 'F' && ['SF', 'PF'].includes(pos)) || 
        slot === 'UTIL' ||
        slot.includes(pos)
      )) {
        return slot;
      }
    }
  }
  return null;
};

const OptimizerUI = () => {
  const navigate = useNavigate();
  const [slates, setSlates] = useState([]);
  const [siteSelection, setSiteSelection] = useState(localStorage.getItem("siteSelection") || "dk");
  const [activeTab, setActiveTab] = useState(0);
  const [selectedSlate, setSelectedSlate] = useState(localStorage.getItem("selectedSlate") || "");
  const [numLineups, setNumLineups] = useState(1);
  const [lineups, setLineups] = useState([]);
  const [news, setNews] = useState([]);
  const [bettingOdds, setBettingOdds] = useState([]);
  const [stats, setStats] = useState([]);
  const [positionFilter, setPositionFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("playerName");
  const [exposure, setExposure] = useState(() => Number(localStorage.getItem("exposure")) || 35);
  const [playerExposures, setPlayerExposures] = useState(() => JSON.parse(localStorage.getItem("playerExposures")) || {});
  const [overriddenPlayers, setOverriddenPlayers] = useState(() => new Set(JSON.parse(localStorage.getItem("overriddenPlayers")) || []));
  const [teamStacks, setTeamStacks] = useState(() => JSON.parse(localStorage.getItem("teamStacks")) || {});
  const [lockedPlayers, setLockedPlayers] = useState(() => JSON.parse(localStorage.getItem("lockedPlayers")) || []);
  const [availableTeams, setAvailableTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [stackSize, setStackSize] = useState(3);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortBy, setSortBy] = useState("projectedPoints");
  const [projectionType, setProjectionType] = useState("Projected");
  const [previewLineup, setPreviewLineup] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/user`, { withCredentials: true })
      .then((res) => {
        if (!res.data.subscription) navigate("/dashboard");
      })
      .catch((err) => {
        console.error("❌ Error fetching subscription data:", err);
        navigate("/login");
      });
  }, [navigate]);

  useEffect(() => {
    const fetchSlates = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/slates`);
        const sortedSlates = res.data.sort((a, b) => b.slateID - a.slateID).slice(0, 5);
        setSlates(sortedSlates);
        if (selectedSlate && !sortedSlates.some(slate => slate.slateID === Number(selectedSlate))) {
          setSelectedSlate("");
          localStorage.removeItem("selectedSlate");
        }
      } catch (error) {
        console.error("❌ Slates API Error:", error);
        setSlates([]);
      }
    };
    fetchSlates();
  }, [siteSelection, selectedSlate]);

  useEffect(() => {
    const fetchProjections = async () => {
      if (selectedSlate) {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/nba_projections`, {
            params: { slateID: selectedSlate, site: siteSelection },
          });
          setStats(res.data);
          const newPlayerExposures = { ...playerExposures };
          res.data.forEach(player => {
            if (!(player.playerID in newPlayerExposures)) {
              newPlayerExposures[player.playerID] = exposure;
            }
          });
          setPlayerExposures(newPlayerExposures);
          localStorage.setItem("playerExposures", JSON.stringify(newPlayerExposures));
        } catch (error) {
          console.error("❌ Projections API Error:", error);
          setStats([]);
        }
      } else {
        setStats([]);
      }
    };
    fetchProjections();
  }, [selectedSlate, siteSelection]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/nba_news`);
        const uniqueNews = Array.from(new Map(res.data.map(item => [item.link, item])).values());
        setNews(uniqueNews);
      } catch (error) {
        console.error("News API Error:", error);
      }
    };

    const fetchBettingOdds = async () => {
      if (selectedSlate) {
        const slate = slates.find(s => s.slateID === Number(selectedSlate));
        if (slate) {
          try {
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/nba_betting_odds`, {
              params: { date: slate.slateDate }
            });
            setBettingOdds(res.data);
          } catch (error) {
            console.error("Betting Odds API Error:", error);
          }
        }
      } else {
        setBettingOdds([]);
      }
    };

    fetchNews();
    fetchBettingOdds();
  }, [selectedSlate, slates]);

  useEffect(() => {
    if (stats.length > 0) {
      const teamsMap = new Map();
      stats.forEach((p) => {
        if (p.teamID && p.teamName) {
          const teamID = String(p.teamID).trim();
          const teamName = p.teamName.trim();
          if (!teamsMap.has(teamID)) {
            teamsMap.set(teamID, {
              id: teamID,
              name: teamName,
              logo: p.teamLogo && p.teamLogo !== "null" ? p.teamLogo : "",
            });
          }
        }
      });
      const sortedTeams = Array.from(teamsMap.values()).sort((a, b) => a.name.localeCompare(b.name));
      setAvailableTeams(sortedTeams);
    }
  }, [stats]);

  useEffect(() => {
    localStorage.setItem("selectedSlate", selectedSlate);
    localStorage.setItem("exposure", exposure);
    localStorage.setItem("playerExposures", JSON.stringify(playerExposures));
    localStorage.setItem("overriddenPlayers", JSON.stringify([...overriddenPlayers]));
    localStorage.setItem("teamStacks", JSON.stringify(teamStacks));
    localStorage.setItem("lockedPlayers", JSON.stringify(lockedPlayers));
  }, [selectedSlate, exposure, playerExposures, overriddenPlayers, teamStacks, lockedPlayers]);

  useEffect(() => {
    const newLineup = {};
    const lockedPlayerStats = stats.filter(p => lockedPlayers.includes(p.playerID));
    
    console.log('Stats data for locked players:', lockedPlayerStats);
    lockedPlayerStats.forEach(player => {
      const position = getAvailablePosition(player, newLineup, siteSelection);
      if (position) {
        newLineup[position] = player;
      } else {
        alert(`No available position for ${player.playerName}`);
      }
    });
    console.log('Preview Lineup from locked players:', newLineup);
    setPreviewLineup(newLineup);
  }, [lockedPlayers, stats, siteSelection]);

  const handleSiteChange = (event) => {
    const newSite = event.target.value;
    if (lineups.length > 0) {
      const confirmSwitch = window.confirm("Switching will clear current lineups and settings. Continue?");
      if (!confirmSwitch) return;
    }
    setSelectedSlate("");
    setLineups([]);
    setPlayerExposures({});
    setOverriddenPlayers(new Set());
    setTeamStacks({});
    setLockedPlayers([]);
    setPreviewLineup({});
    localStorage.removeItem("selectedSlate");
    localStorage.removeItem("playerExposures");
    localStorage.removeItem("overriddenPlayers");
    localStorage.removeItem("teamStacks");
    localStorage.removeItem("lockedPlayers");
    setSiteSelection(newSite);
    localStorage.setItem("siteSelection", newSite);
  };

  const handleExposureChange = (e, val) => {
    setExposure(val);
    const updatedExposures = {};
    stats.forEach(player => {
      updatedExposures[player.playerID] = overriddenPlayers.has(player.playerID) 
        ? playerExposures[player.playerID] 
        : val;
    });
    setPlayerExposures(updatedExposures);
  };

  const renderQuestionableStatus = (questionable, recommendation) => {
    const status = questionable === "Q" || questionable === "P" ? (
      <span style={{ color: "#ff5252", marginLeft: "4px", fontWeight: "bold" }}>
        {questionable}
      </span>
    ) : null;

    const icon = recommendation === "Play" ? (
      <LocalFireDepartmentIcon sx={{ color: '#ff5722', fontSize: '18px', ml: 1 }} />
    ) : recommendation === "Avoid" ? (
      <AcUnitIcon sx={{ color: '#00bcd4', fontSize: '18px', ml: 1 }} />
    ) : null;

    return (
      <>
        {status}
        {icon}
      </>
    );
  };

  const handlePlayerExposureChange = (playerId, value) => {
    const newValue = Math.max(0, Math.min(100, Number(value)));
    setPlayerExposures(prev => ({ ...prev, [playerId]: newValue }));
    setOverriddenPlayers(prev => new Set(prev).add(playerId));
  };

  const handleOptimize = () => {
    if (!selectedSlate) {
      alert("Please Select Slate First!");
      return;
    }

    const params = {
      slateID: Number(selectedSlate),
      numLineups: Number(numLineups),
      exposure_limit: Number(exposure) / 100,
      player_exposures: playerExposures || {},
      team_stacks: Object.fromEntries(Object.entries(teamStacks).map(([k, v]) => [Number(k), Number(v)])),
      locked_players: lockedPlayers,
      projectionType: projectionType.toLowerCase(),
    };

    const apiEndpoint = siteSelection === "dk" ? "run_optimizer_dk" : "run_optimizer_fd";
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/${apiEndpoint}`, params)
      .then((res) => {
        if (numLineups === 1) {
          setPreviewLineup(res.data.lineups[0] || {});
        } else {
          setLineups(res.data.lineups || []);
          setActiveTab(1);
        }
      })
      .catch((error) => {
        console.error("Optimization Error:", error);
        alert(`Optimization failed: ${error.response?.data?.error || error.message}`);
      });
  };

  const exportLineupsToCSV = () => {
    if (lineups.length === 0) {
      alert("No lineups to export!");
      return;
    }

    const headers = siteSelection === "dk" 
      ? ["PG", "SG", "SF", "PF", "C", "G", "F", "UTIL"] 
      : ["PG", "PG", "SG", "SG", "SF", "SF", "PF", "PF", "C"];
    const csvRows = [headers.join(",")];

    lineups.forEach((lineup) => {
      const lineupPositions = {};
      Object.keys(lineup).forEach((pos) => {
        if (typeof lineup[pos] === "object" && lineup[pos].dkID) {
          const positionKey = pos.split("_")[0];
          lineupPositions[positionKey] = lineup[pos].dkID;
        }
      });

      const row = headers.map((header) => lineupPositions[header] || "");
      csvRows.push(row.join(","));
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `lineups_${selectedSlate}_${siteSelection}.csv`;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const filteredStats = stats
    .filter((player) =>
      player.playerName &&
      player.playerName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (positionFilter === "" || (player.position && player.position.includes(positionFilter)))
    )
    .sort((a, b) => {
      let valueA = a[sortColumn];
      let valueB = b[sortColumn];
      if (sortColumn === "salary" || sortColumn === "projectedPTS" || sortColumn === "ceilingPTS" || sortColumn === "ownership") {
        valueA = Number(valueA) || 0;
        valueB = Number(valueB) || 0;
        return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
      }
      valueA = valueA || "";
      valueB = valueB || "";
      if (typeof valueA === "string") valueA = valueA.toLowerCase();
      if (typeof valueB === "string") valueB = valueB.toLowerCase();
      return sortDirection === "asc" ? (valueA > valueB ? 1 : -1) : (valueA < valueB ? 1 : -1);
    });

  const calculatePlayerExposures = () => {
    const exposureCount = {};
    lineups.forEach(lineup => {
      Object.values(lineup).forEach(player => {
        if (typeof player === 'object' && player.playerID) {
          exposureCount[player.playerID] = (exposureCount[player.playerID] || 0) + 1;
        }
      });
    });
    return Object.entries(exposureCount).map(([playerID, count]) => {
      const player = stats.find(p => p.playerID === playerID);
      return {
        playerName: player?.playerName || 'Unknown',
        exposure: ((count / lineups.length) * 100).toFixed(1)
      };
    });
  };

  const sortedLineups = [...lineups].sort((a, b) => {
    if (sortBy === "projectedPoints") {
      const aPoints = siteSelection === "dk" ? a.ProjectedDKPoints : a.ProjectedFDPoints;
      const bPoints = siteSelection === "dk" ? b.ProjectedDKPoints : b.ProjectedFDPoints;
      return bPoints - aPoints;
    } else if (sortBy === "ceilingPoints") {
      const aPoints = siteSelection === "dk" ? a.DKCeilingPoints : a.FDCeilingPoints;
      const bPoints = siteSelection === "dk" ? b.DKCeilingPoints : b.FDCeilingPoints;
      return bPoints - aPoints;
    } else if (sortBy === "totalSalary") {
      const aSalary = parseInt(a.TotalSalary.replace(/[$,]/g, ""));
      const bSalary = parseInt(b.TotalSalary.replace(/[$,]/g, ""));
      return bSalary - aSalary;
    }
    return 0;
  });

  const calculatePreviewTotals = () => {
    const positions = siteSelection === 'dk' ? 8 : 9;
    const maxSalary = siteSelection === 'dk' ? 50000 : 60000;
    
    const totalSalary = Object.values(previewLineup)
      .reduce((sum, player) => sum + (player && typeof player.salary === 'number' ? player.salary : 0), 0);
    const totalProjected = Object.values(previewLineup)
      .reduce((sum, player) => sum + (player && player.projectedPTS != null ? Number(player.projectedPTS) : 0), 0);
    const totalCeiling = Object.values(previewLineup)
      .reduce((sum, player) => sum + (player && typeof player.ceilingPTS === 'number' ? player.ceilingPTS : 0), 0);
    const filledSpots = Object.keys(previewLineup).length;
    
    return {
      salary: totalSalary,
      remainingSalary: maxSalary - totalSalary,
      perPlayerRemaining: filledSpots < positions ? (maxSalary - totalSalary) / (positions - filledSpots) : 0,
      projected: totalProjected,
      ceiling: totalCeiling
    };
  };

  const formatTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffMs = now - date;
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    if (diffHours < 1) return "Less than an hour ago";
    if (diffHours < 24) return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    const diffDays = Math.floor(diffHours / 24);
    return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
  };

  const clearLockedPlayers = () => {
    setLockedPlayers([]);
    setPreviewLineup({});
  };

  const clearPlayerExposures = () => {
    setPlayerExposures({});
    setOverriddenPlayers(new Set());
  };

  return (
    <Container sx={{ bgcolor: '#1a1a1a', minHeight: '100vh', py: 4, color: '#fff', maxWidth: "100%", width: "100%", px: 2 }}>
      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)} 
        variant="fullWidth" 
        sx={{ 
          mb: 3, 
          bgcolor: '#2c2c2c', 
          borderRadius: 2, 
          '& .MuiTab-root': { 
            color: '#fff', 
            fontWeight: 'bold', 
            textTransform: 'uppercase', 
            transition: 'all 0.3s ease',
            '&:hover': { bgcolor: '#3d3d3d' }
          }, 
          '& .Mui-selected': { 
            color: '#00e676', 
            bgcolor: '#3d3d3d' 
          }, 
          '& .MuiTabs-indicator': { bgcolor: '#00e676', height: 4 }
        }}
      >
        <Tab label="Optimizer" />
        <Tab label="Results" />
        <Tab label="News" />
        <Tab label="Betting Odds" />
      </Tabs>

      <Grid container spacing={3}>
        {activeTab === 0 && (
          <Grid item xs={12}>
            <Box sx={{ 
              display: "flex", 
              gap: 2, 
              mb: 4, 
              flexWrap: "wrap", 
              bgcolor: '#2c2c2c', 
              p: 2, 
              borderRadius: 2, 
              boxShadow: '0 4px 20px rgba(0,0,0,0.3)' 
            }}>
              <TextField 
                select 
                label="Select Site" 
                value={siteSelection} 
                onChange={handleSiteChange} 
                sx={{ 
                  minWidth: 150, 
                  '& .MuiInputBase-root': { bgcolor: '#3d3d3d', color: '#fff', borderRadius: 1 }, 
                  '& .MuiInputLabel-root': { color: '#b0b0b0' },
                  '& .Mui-focused .MuiInputLabel-root': { color: '#00e676' },
                  '& .MuiOutlinedInput-notchedOutline': { borderColor: '#00e676' }
                }}
              >
                <MenuItem value="dk">DraftKings</MenuItem>
                <MenuItem value="fd">FanDuel</MenuItem>
              </TextField>
              <TextField 
                select 
                label="Select Slate" 
                value={selectedSlate} 
                onChange={(e) => setSelectedSlate(e.target.value)} 
                sx={{ 
                  minWidth: 200, 
                  '& .MuiInputBase-root': { bgcolor: '#3d3d3d', color: '#fff', borderRadius: 1 }, 
                  '& .MuiInputLabel-root': { color: '#b0b0b0' },
                  '& .Mui-focused .MuiInputLabel-root': { color: '#00e676' }
                }}
              >
                {slates.map((slate) => (
                  <MenuItem key={slate.slateID} value={slate.slateID}>{slate.slateName}</MenuItem>
                ))}
              </TextField>
              <TextField 
                select 
                label="Projection Type" 
                value={projectionType} 
                onChange={(e) => setProjectionType(e.target.value)} 
                sx={{ 
                  minWidth: 150, 
                  '& .MuiInputBase-root': { bgcolor: '#3d3d3d', color: '#fff', borderRadius: 1 }, 
                  '& .MuiInputLabel-root': { color: '#b0b0b0' },
                  '& .Mui-focused .MuiInputLabel-root': { color: '#00e676' }
                }}
              >
                <MenuItem value="Projected">Projected</MenuItem>
                <MenuItem value="Ceiling">Ceiling</MenuItem>
              </TextField>
              <TextField 
                type="number" 
                label="Number of Lineups" 
                value={numLineups} 
                onChange={(e) => setNumLineups(e.target.value)} 
                sx={{ 
                  minWidth: 150, 
                  '& .MuiInputBase-root': { bgcolor: '#3d3d3d', color: '#fff', borderRadius: 1 }, 
                  '& .MuiInputLabel-root': { color: '#b0b0b0' },
                  '& .Mui-focused .MuiInputLabel-root': { color: '#00e676' }
                }} 
              />
              <Button 
                variant="contained" 
                onClick={handleOptimize} 
                sx={{ 
                  bgcolor: 'linear-gradient(45deg, #00e676 30%, #00c853 90%)', 
                  color: '#fff', 
                  fontWeight: 'bold', 
                  px: 4, 
                  borderRadius: 2, 
                  boxShadow: '0 4px 15px rgba(0, 230, 118, 0.4)', 
                  transition: 'all 0.3s ease', 
                  '&:hover': { 
                    bgcolor: 'linear-gradient(45deg, #00c853 30%, #00e676 90%)', 
                    boxShadow: '0 6px 20px rgba(0, 230, 118, 0.6)' 
                  } 
                }}
              >
                Optimize
              </Button>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography gutterBottom sx={{ color: '#00e676', fontWeight: 'bold', mb: 1 }}>
                Global Exposure Limit (%)
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Slider 
                  value={exposure} 
                  onChange={handleExposureChange} 
                  min={0} 
                  max={100} 
                  valueLabelDisplay="auto" 
                  sx={{ 
                    maxWidth: 300, 
                    color: '#00e676', 
                    '& .MuiSlider-thumb': { 
                      boxShadow: '0 0 10px rgba(0, 230, 118, 0.5)' 
                    }, 
                    '& .MuiSlider-rail': { bgcolor: '#3d3d3d' } 
                  }} 
                />
                <TextField
                  type="number"
                  value={exposure}
                  onChange={(e) => {
                    const newValue = Math.max(0, Math.min(100, Number(e.target.value)));
                    setExposure(newValue);
                    handleExposureChange(null, newValue);
                  }}
                  inputProps={{ min: 0, max: 100, step: 1 }}
                  size="small"
                  sx={{ 
                    width: 80,
                    '& .MuiInputBase-root': { bgcolor: '#3d3d3d', color: '#fff', borderRadius: 1 }, 
                    '& .MuiInputLabel-root': { color: '#b0b0b0' },
                    '& .Mui-focused .MuiInputLabel-root': { color: '#00e676' }
                  }}
                />
              </Box>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography gutterBottom sx={{ color: '#00e676', fontWeight: 'bold', mb: 2 }}>Team Stacking</Typography>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Select 
                    fullWidth 
                    value={selectedTeam} 
                    onChange={(e) => setSelectedTeam(e.target.value)} 
                    displayEmpty
                    sx={{ 
                      bgcolor: '#3d3d3d', 
                      color: '#fff', 
                      borderRadius: 1, 
                      '& .MuiOutlinedInput-notchedOutline': { borderColor: '#00e676' },
                      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#00e676' }
                    }}
                  >
                    <MenuItem value="" disabled>Select Team</MenuItem>
                    {availableTeams.map((team) => (
                      <MenuItem key={team.id} value={team.id}>
                        <Box display="flex" alignItems="center">
                          {team.logo && <img src={team.logo} alt={team.name} style={{ height: 20, marginRight: 8 }} />}
                          {team.name || "Unknown Team"}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <TextField 
                    type="number" 
                    label="Min Players" 
                    value={stackSize} 
                    onChange={(e) => setStackSize(e.target.value)} 
                    inputProps={{ min: 1, max: 4 }} 
                    sx={{ 
                      '& .MuiInputBase-root': { bgcolor: '#3d3d3d', color: '#fff', borderRadius: 1 }, 
                      '& .MuiInputLabel-root': { color: '#b0b0b0' },
                      '& .Mui-focused .MuiInputLabel-root': { color: '#00e676' }
                    }} 
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button 
                    variant="contained" 
                    onClick={() => { setTeamStacks({ ...teamStacks, [selectedTeam]: stackSize }); setSelectedTeam(""); }} 
                    disabled={!selectedTeam}
                    sx={{ 
                      bgcolor: '#00e676', 
                      color: '#fff', 
                      borderRadius: 2, 
                      transition: 'all 0.3s ease', 
                      '&:hover': { bgcolor: '#00c853' } 
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{ mt: 2 }}>
                {Object.entries(teamStacks).map(([teamId, size]) => {
                  const team = availableTeams.find((t) => t.id === teamId);
                  return (
                    <Chip 
                      key={teamId} 
                      label={
                        <Box display="flex" alignItems="center">
                          {team?.logo && <img src={team.logo} alt={team.name} style={{ height: 20, marginRight: 4 }} />}
                          {team ? team.name : teamId} ({size})
                        </Box>
                      } 
                      onDelete={() => {
                        const newStacks = { ...teamStacks };
                        delete newStacks[teamId];
                        setTeamStacks(newStacks);
                      }} 
                      sx={{ 
                        m: 0.5, 
                        bgcolor: '#3d3d3d', 
                        color: '#fff', 
                        '&:hover': { bgcolor: '#4d4d4d' } 
                      }} 
                    />
                  );
                })}
              </Box>
            </Box>

            <Box sx={{ mb: 4, display: 'flex', gap: 2 }}>
              <Button 
                variant="outlined" 
                onClick={clearLockedPlayers}
                sx={{ 
                  borderColor: '#00e676', 
                  color: '#00e676', 
                  transition: 'all 0.3s ease', 
                  '&:hover': { bgcolor: '#00e676', color: '#fff', borderColor: '#00e676' } 
                }}
              >
                Clear Locked Players
              </Button>
              <Button 
                variant="outlined" 
                onClick={clearPlayerExposures}
                sx={{ 
                  borderColor: '#00e676', 
                  color: '#00e676', 
                  transition: 'all 0.3s ease', 
                  '&:hover': { bgcolor: '#00e676', color: '#fff', borderColor: '#00e676' } 
                }}
              >
                Clear Exposure Overrides
              </Button>
            </Box>

            <Card
              sx={{
                height: isMobile ? "70vh" : "600px",
                bgcolor: "#2c2c2c",
                borderRadius: 2,
                boxShadow: "0 4px 20px rgba(0,0,0,0.5)",
                transition: "all 0.3s ease",
                "&:hover": { boxShadow: "0 6px 25px rgba(0, 230, 118, 0.2)" },
                width: "100%", // Full width of the container
              }}
            >
              <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    mb: 2,
                    color: "#00e676",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  Player Stats
                </Typography>
                <Box sx={{ display: "flex", gap: 2, mb: 2, flexWrap: "wrap", alignItems: "center" }}>
                  <TextField
                    label="Search Player"
                    fullWidth={isMobile}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{
                      flex: 1,
                      minWidth: 200,
                      "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff", borderRadius: 1 },
                      "& .MuiInputLabel-root": { color: "#b0b0b0" },
                      "& .Mui-focused .MuiInputLabel-root": { color: "#00e676" },
                    }}
                  />
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {["PG", "SG", "SF", "PF", "C"].map((pos) => (
                      <Chip
                        key={pos}
                        label={pos}
                        onClick={() => setPositionFilter(positionFilter === pos ? "" : pos)}
                        color={positionFilter === pos ? "primary" : "default"}
                        clickable
                        sx={{
                          bgcolor: positionFilter === pos ? "#00e676" : "#3d3d3d",
                          color: "#fff",
                          transition: "all 0.3s ease",
                          "&:hover": { bgcolor: positionFilter === pos ? "#00c853" : "#4d4d4d" },
                        }}
                      />
                    ))}
                  </Box>
                </Box>
                <Box sx={{ flex: 1, overflowY: "auto", overflowX: isMobile ? "auto" : "auto", width: "100%" }}>
                  <Table
                    sx={{
                      minWidth: "1800px", // Ensure table is wide enough for all columns
                      bgcolor: "#2c2c2c",
                      width: "max-content", // Allow table to take its natural width
                    }}
                  >
                    <TableHead
                      sx={{
                        bgcolor: "#3d3d3d",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      <TableRow>
                        <TableCell sx={{ minWidth: 220 }}>
                          <Button
                            onClick={() => {
                              setSortColumn("playerName");
                              setSortDirection(
                                sortColumn === "playerName" && sortDirection === "asc" ? "desc" : "asc"
                              );
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Player {sortColumn === "playerName" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ minWidth: 100 }}>
                          <Button
                            onClick={() => {
                              setSortColumn("position");
                              setSortDirection(
                                sortColumn === "position" && sortDirection === "asc" ? "desc" : "asc"
                              );
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Position {sortColumn === "position" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ minWidth: 100 }}>
                          <Button
                            onClick={() => {
                              setSortColumn("teamName");
                              setSortDirection(
                                sortColumn === "teamName" && sortDirection === "asc" ? "desc" : "asc"
                              );
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Team {sortColumn === "teamName" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ minWidth: 100 }}>
                          <Button
                            onClick={() => {
                              setSortColumn("opponentTeam");
                              setSortDirection(
                                sortColumn === "opponentTeam" && sortDirection === "asc" ? "desc" : "asc"
                              );
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Opponent {sortColumn === "opponentTeam" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ minWidth: 80 }}>
                          <Button
                            onClick={() => {
                              setSortColumn("salary");
                              setSortDirection(
                                sortColumn === "salary" && sortDirection === "asc" ? "desc" : "asc"
                              );
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Salary {sortColumn === "salary" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ minWidth: 80 }}>
                          <Button
                            onClick={() => {
                              setSortColumn("projectedPTS");
                              setSortDirection(
                                sortColumn === "projectedPTS" && sortDirection === "asc" ? "desc" : "asc"
                              );
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Projected {sortColumn === "projectedPTS" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ minWidth: 80 }}>
                          <Button
                            onClick={() => {
                              setSortColumn("ceilingPTS");
                              setSortDirection(
                                sortColumn === "ceilingPTS" && sortDirection === "asc" ? "desc" : "asc"
                              );
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Ceiling {sortColumn === "ceilingPTS" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ minWidth: 80 }}>
                          <Button
                            onClick={() => {
                              setSortColumn("ownership");
                              setSortDirection(
                                sortColumn === "ownership" && sortDirection === "asc" ? "desc" : "asc"
                              );
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Ownership {sortColumn === "ownership" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ minWidth: 80 }}>
                          <Button
                            onClick={() => {
                              setSortColumn("value_score");
                              setSortDirection(
                                sortColumn === "value_score" && sortDirection === "asc" ? "desc" : "asc"
                              );
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Value {sortColumn === "value_score" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ minWidth: 80 }}>
                          <Button
                            onClick={() => {
                              setSortColumn("matchup_edge");
                              setSortDirection(
                                sortColumn === "matchup_edge" && sortDirection === "asc" ? "desc" : "asc"
                              );
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Matchup {sortColumn === "matchup_edge" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ minWidth: 80 }}>
                          <Button
                            onClick={() => {
                              setSortColumn("dk_boom_prob");
                              setSortDirection(
                                sortColumn === "dk_boom_prob" && sortDirection === "asc" ? "desc" : "asc"
                              );
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Boom % {sortColumn === "dk_boom_prob" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ minWidth: 80 }}>
                          <Button
                            onClick={() => {
                              setSortColumn("recommendation");
                              setSortDirection(
                                sortColumn === "recommendation" && sortDirection === "asc" ? "desc" : "asc"
                              );
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Rec {sortColumn === "recommendation" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ color: "#fff", minWidth: 80 }}>Exposure (%)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredStats.map((player) => (
                        <TableRow
                          key={player.playerID}
                          sx={{
                            "&:hover": { bgcolor: "#3d3d3d", transition: "background-color 0.2s ease" },
                            color: "#fff",
                          }}
                        >
                          <TableCell sx={{ color: "#fff" }}>
                            <IconButton
                              onClick={() => {
                                const playerId = String(player.playerID);
                                setLockedPlayers((prev) =>
                                  prev.includes(playerId)
                                    ? prev.filter((id) => id !== playerId)
                                    : [...prev, playerId]
                                );
                              }}
                              sx={{ color: lockedPlayers.includes(player.playerID) ? "#00e676" : "#fff" }}
                            >
                              {lockedPlayers.includes(player.playerID) ? <LockIcon /> : <LockOpenIcon />}
                            </IconButton>
                            {player.playerName}
                            {renderQuestionableStatus(player.questionable, player.recommendation)}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>{player.position}</TableCell>
                          <TableCell sx={{ color: "#fff" }}>{player.teamName}</TableCell>
                          <TableCell sx={{ color: "#fff" }}>{player.opponentTeam || "N/A"}</TableCell>
                          <TableCell sx={{ color: "#fff" }}>${player.salary}</TableCell>
                          <TableCell sx={{ color: "#fff" }}>{Number(player.projectedPTS).toFixed(2)}</TableCell>
                          <TableCell sx={{ color: "#fff" }}>{player.ceilingPTS}</TableCell>
                          <TableCell sx={{ color: "#fff" }}>{player.projectedOwnership ? `${player.projectedOwnership}%` : "N/A"}</TableCell>
                          <TableCell sx={{ color: "#fff" }}>{player.value_score !== undefined ? Number(player.value_score).toFixed(2) : "N/A"}</TableCell>
                          <TableCell sx={{ color: "#fff" }}>{player.matchup_edge !== undefined ? Number(player.matchup_edge).toFixed(2) : "N/A"}</TableCell>
                          <TableCell sx={{ color: "#fff" }}>{player.dk_boom_prob !== undefined ? `${(player.dk_boom_prob * 100).toFixed(1)}%` : "N/A"}</TableCell>
                          <TableCell sx={{ color: "#fff" }}>{player.recommendation || "N/A"}</TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              value={
                                playerExposures[player.playerID] !== undefined
                                  ? playerExposures[player.playerID]
                                  : exposure
                              }
                              onChange={(e) => handlePlayerExposureChange(player.playerID, e.target.value)}
                              inputProps={{ min: 0, max: 100, step: 1, style: { width: 60, color: "#fff" } }}
                              size="small"
                              sx={{
                                "& .MuiInputBase-root": { bgcolor: "#3d3d3d", borderRadius: 1 },
                                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#00e676" },
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </CardContent>
            </Card>

            {/* Updated Preview Lineup Card */}
            <Card sx={{
              mt: 4,
              bgcolor: '#2c2c2c',
              borderRadius: 2,
              boxShadow: '0 4px 20px rgba(0,0,0,0.5)',
              transition: 'all 0.3s ease',
              '&:hover': { boxShadow: '0 6px 25px rgba(0, 230, 118, 0.2)' }
            }}>
              <CardContent sx={{ height: isMobile ? "50vh" : "400px", display: "flex", flexDirection: "column" }}>
                <Typography variant="h6" sx={{ mb: 2, color: '#00e676', fontWeight: 'bold' }}>
                  Lineup Preview
                </Typography>
                <Box sx={{ flex: 1, overflowY: "auto" }}>
                  <Table sx={{ minWidth: isMobile ? "700px" : "100%", bgcolor: '#2c2c2c' }}>
                    <TableHead sx={{ bgcolor: '#3d3d3d', position: "sticky", top: 0, zIndex: 1 }}>
                      <TableRow>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Position</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Player</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Pos</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Team</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Opp</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Salary</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Proj</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Ceil</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Own</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(siteSelection === 'dk' ? 
                        ['PG', 'SG', 'SF', 'PF', 'C', 'G', 'F', 'UTIL'] :
                        ['PG_1', 'PG_2', 'SG_1', 'SG_2', 'SF_1', 'SF_2', 'PF_1', 'PF_2', 'C']
                      ).map((pos) => {
                        const player = previewLineup[pos];
                        return (
                          <TableRow key={pos} sx={{ '&:hover': { bgcolor: '#3d3d3d' } }}>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>{pos.replace('_1', '').replace('_2', '')}</TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>
                              {player ? player.playerName : '-'}
                              {player && renderQuestionableStatus(player.questionable, player.recommendation)}
                            </TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>{player ? player.position : '-'}</TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>{player ? player.teamName : '-'}</TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>{player ? player.opponentTeam || 'N/A' : '-'}</TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>
                              {player && typeof player.salary === 'number' ? `$${player.salary.toLocaleString()}` : '-'}
                            </TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>
                              {player && player.projectedPTS != null ? Number(player.projectedPTS).toFixed(2) : '-'}
                            </TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>
                              {player && typeof player.ceilingPTS === 'number' ? player.ceilingPTS.toFixed(2) : '-'}
                            </TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>
                              {player && typeof player.projectedOwnership === 'number' ? `${player.projectedOwnership}%` : '-'}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {(() => {
                        const totals = calculatePreviewTotals();
                        return (
                          <TableRow sx={{ bgcolor: '#3d3d3d', fontWeight: 'bold' }}>
                            <TableCell colSpan={5} sx={{ color: '#fff', py: 0.5 }}><strong>Total</strong></TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>
                              <strong>${totals.salary.toLocaleString()}</strong><br/>
                              <small>Rem: ${totals.remainingSalary.toLocaleString()}</small><br/>
                              <small>Per: ${Number(totals.perPlayerRemaining).toFixed(0)}</small>
                            </TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>
                              <strong>{typeof totals.projected === 'number' ? totals.projected.toFixed(2) : '0.00'}</strong>
                            </TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>
                              <strong>{typeof totals.ceiling === 'number' ? totals.ceiling.toFixed(2) : '0.00'}</strong>
                            </TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}></TableCell>
                          </TableRow>
                        );
                      })()}
                    </TableBody>
                  </Table>
                </Box>
              </CardContent>
            </Card>

            <Box sx={{ mt: 3, textAlign: "center" }}>
              <Button 
                variant="text" 
                onClick={() => navigate("/dashboard")} 
                sx={{ 
                  color: '#00e676', 
                  fontWeight: 'bold', 
                  transition: 'all 0.3s ease', 
                  '&:hover': { color: '#00c853' } 
                }}
              >
                Return to Dashboard
              </Button>
            </Box>
          </Grid>
        )}

        {activeTab === 1 && (
          <Grid item xs={12}>
            <Card sx={{ 
              height: isMobile ? "auto" : "645px", 
              bgcolor: '#2c2c2c', 
              borderRadius: 2, 
              boxShadow: '0 4px 20px rgba(0,0,0,0.5)', 
              transition: 'all 0.3s ease', 
              '&:hover': { boxShadow: '0 6px 25px rgba(0, 230, 118, 0.2)' } 
            }}>
              <CardContent sx={{ height: "100%", overflowY: "auto" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                  <Typography 
                    variant="h6" 
                    sx={{ color: '#00e676', fontWeight: 'bold', textTransform: 'uppercase' }}
                  >
                    Lineup Results
                  </Typography>
                  <Button 
                    variant="outlined" 
                    onClick={exportLineupsToCSV} 
                    sx={{ 
                      borderColor: '#00e676', 
                      color: '#00e676', 
                      transition: 'all 0.3s ease', 
                      '&:hover': { bgcolor: '#00e676', color: '#fff', borderColor: '#00e676' } 
                    }}
                  >
                    Export Lineups
                  </Button>
                </Box>
                {lineups.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <TextField 
                      select 
                      label="Sort By" 
                      value={sortBy} 
                      onChange={(e) => setSortBy(e.target.value)} 
                      size="small" 
                      sx={{ 
                        minWidth: 150, 
                        '& .MuiInputBase-root': { bgcolor: '#3d3d3d', color: '#fff', borderRadius: 1 }, 
                        '& .MuiInputLabel-root': { color: '#b0b0b0' },
                        '& .Mui-focused .MuiInputLabel-root': { color: '#00e676' }
                      }}
                    >
                      <MenuItem value="projectedPoints">Projected Points</MenuItem>
                      <MenuItem value="ceilingPoints">Ceiling Points</MenuItem>
                      <MenuItem value="totalSalary">Total Salary</MenuItem>
                    </TextField>
                  </Box>
                )}
                <div style={{ overflowX: "auto" }}>
                  {sortedLineups.map((lineup, index) => {
                    let totalSalary = 0;
                    let totalProjected = 0;
                    let totalCeiling = 0;

                    return (
                      <Box key={index} sx={{ mb: 4 }}>
                        <Typography 
                          variant="h6" 
                          sx={{ mb: 1, color: '#00e676', fontWeight: 'bold' }}
                        >
                          Lineup {index + 1}
                        </Typography>
                        <Table sx={{ minWidth: isMobile ? "800px" : "100%", bgcolor: '#2c2c2c' }}>
                          <TableHead sx={{ bgcolor: '#3d3d3d' }}>
                            <TableRow>
                              <TableCell sx={{ color: '#fff' }}>Position</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Player</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Position</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Team</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Opponent</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Salary</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Projected</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Ceiling</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Ownership</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.keys(lineup).map((pos) => {
                              if (typeof lineup[pos] === "object") {
                                totalSalary += lineup[pos].salary || 0;
                                totalProjected += lineup[pos].projectedPTS || 0;
                                totalCeiling += lineup[pos].ceilingPTS || 0;
                                return (
                                  <TableRow 
                                    key={pos} 
                                    sx={{ 
                                      '&:hover': { bgcolor: '#3d3d3d', transition: 'background-color 0.2s ease' }, 
                                      color: '#fff' 
                                    }}
                                  >
                                    <TableCell sx={{ color: '#fff' }}>{pos.split("_")[0]}</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>
                                      {lineup[pos].playerName}
                                      {renderQuestionableStatus(lineup[pos].questionable, lineup[pos].recommendation)}
                                    </TableCell>
                                    <TableCell sx={{ color: '#fff' }}>{lineup[pos].position}</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>{lineup[pos].teamName}</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>{lineup[pos].opponentTeam || 'N/A'}</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>${lineup[pos].salary?.toLocaleString()}</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>{lineup[pos].projectedPTS?.toFixed(2)}</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>{lineup[pos].ceilingPTS?.toFixed(2)}</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>{lineup[pos].projectedOwnership ? `${lineup[pos].projectedOwnership}%` : 'N/A'}</TableCell>
                                  </TableRow>
                                );
                              }
                              return null;
                            })}
                            <TableRow sx={{ bgcolor: '#3d3d3d', fontWeight: 'bold' }}>
                              <TableCell colSpan={5} sx={{ color: '#fff' }}><strong>Total</strong></TableCell>
                              <TableCell sx={{ color: '#fff' }}><strong>${totalSalary.toLocaleString()}</strong></TableCell>
                              <TableCell sx={{ color: '#fff' }}><strong>{totalProjected.toFixed(2)}</strong></TableCell>
                              <TableCell sx={{ color: '#fff' }}><strong>{totalCeiling.toFixed(2)}</strong></TableCell>
                              <TableCell sx={{ color: '#fff' }}></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    );
                  })}
                  {sortedLineups.length > 0 && (
                    <>
                      <Typography 
                        variant="h6" 
                        sx={{ mt: 4, mb: 2, color: '#00e676', fontWeight: 'bold', textTransform: 'uppercase' }}
                      >
                        Player Exposure Summary
                      </Typography>
                      <Table sx={{ maxWidth: "400px", bgcolor: '#2c2c2c' }}>
                        <TableHead sx={{ bgcolor: '#3d3d3d' }}>
                          <TableRow>
                            <TableCell sx={{ color: '#fff' }}>Player</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Exposure (%)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {calculatePlayerExposures().map((exp, index) => (
                            <TableRow 
                              key={index} 
                              sx={{ 
                                '&:hover': { bgcolor: '#3d3d3d', transition: 'background-color 0.2s ease' }, 
                                color: '#fff' 
                              }}
                            >
                              <TableCell sx={{ color: '#fff' }}>{exp.playerName}</TableCell>
                              <TableCell sx={{ color: '#fff' }}>{exp.exposure}%</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Typography 
                        variant="body1" 
                        sx={{ mt: 2, textAlign: "center", color: '#00e676', fontWeight: 'bold' }}
                      >
                        Total Lineups: {sortedLineups.length}
                      </Typography>
                    </>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}

        {activeTab === 2 && (
          <Grid item xs={12}>
            <Card sx={{ 
              maxHeight: isMobile ? "auto" : "auto",
              bgcolor: '#2c2c2c', 
              borderRadius: 2, 
              boxShadow: '0 4px 20px rgba(0,0,0,0.5)', 
              transition: 'all 0.3s ease', 
              '&:hover': { boxShadow: '0 6px 25px rgba(0, 230, 118, 0.2)' } 
            }}>
              <CardContent>
                <Typography 
                  variant="h6" 
                  sx={{ color: '#00e676', fontWeight: 'bold', mb: 2, textTransform: 'uppercase' }}
                >
                  NBA News
                </Typography>
                <div style={{ flex: 1, overflowY: "auto", paddingRight: "10px" }}>
                  {news.length > 0 ? news.map((item, index) => (
                    <Typography 
                      key={index} 
                      sx={{ 
                        mb: 2, 
                        transition: 'all 0.3s ease', 
                        '&:hover': { color: '#00e676' } 
                      }}
                    >
                      <a 
                        href={item.link} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        {item.title.length > 60 ? `${item.title.substring(0, 60)}...` : item.title}
                      </a>
                      <Typography variant="caption" display="block" sx={{ color: '#b0b0b0' }}>
                        {formatTimeAgo(item.createdAt)}
                      </Typography>
                    </Typography>
                  )) : (
                    <Typography sx={{ color: '#fff' }}>No news available at this time.</Typography>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}

        {activeTab === 3 && (
          <Grid item xs={12}>
            <Card sx={{ 
              maxHeight: isMobile ? "auto" : "auto",
              bgcolor: '#2c2c2c', 
              borderRadius: 2, 
              boxShadow: '0 4px 20px rgba(0,0,0,0.5)', 
              transition: 'all 0.3s ease', 
              '&:hover': { boxShadow: '0 6px 25px rgba(0, 230, 118, 0.2)' } 
            }}>
              <CardContent>
                <Typography 
                  variant="h6" 
                  sx={{ color: '#00e676', fontWeight: 'bold', mb: 2, textTransform: 'uppercase' }}
                >
                  Betting Odds
                </Typography>
                <div style={{ flex: 1, overflowY: "auto", paddingRight: "10px" }}>
                  {bettingOdds.length > 0 ? bettingOdds.map((game, index) => {
                    const calculateImpliedTotals = (spread, total) => {
                      if (!spread || !total) return { home: 'N/A', away: 'N/A' };
                      const adjustedTotal = total / 2;
                      const homeImplied = adjustedTotal - (spread / 2);
                      const awayImplied = adjustedTotal + (spread / 2);
                      return { home: homeImplied.toFixed(1), away: awayImplied.toFixed(1) };
                    };

                    const sportsbooks = [
                      { name: 'BetMGM', prefix: 'betmgm' },
                      { name: 'Bet365', prefix: 'bet365' },
                      { name: 'FanDuel', prefix: 'fanduel' },
                      { name: 'ESPN BET', prefix: 'espnbet' },
                      { name: 'Caesars', prefix: 'caesars' },
                      { name: 'DraftKings', prefix: 'draftkings' }
                    ];

                    return (
                      <Box key={index} sx={{ mb: 3, borderBottom: '1px solid #3d3d3d', pb: 2 }}>
                        <Typography sx={{ color: '#fff', fontWeight: 'bold', mb: 1 }}>
                          <img src={game.homeTeamLogo} alt={game.homeTeam} width="20" height="20" style={{ marginRight: 4 }} /> 
                          {game.homeTeam} vs.
                          <img src={game.awayTeamLogo} alt={game.awayTeam} width="20" height="20" style={{ marginLeft: 4, marginRight: 4 }} /> 
                          {game.awayTeam}
                        </Typography>
                        
                        {sportsbooks.map((book) => {
                          const prefix = book.prefix;
                          const hasData = game[`${prefix}_awaySpread`] || game[`${prefix}_homeMLOdds`];
                          if (!hasData) return null;

                          const impliedTotals = calculateImpliedTotals(
                            game[`${prefix}_homeSpread`],
                            game[`${prefix}_totalOver`]
                          );

                          return (
                            <Box 
                              key={book.name}
                              sx={{ 
                                border: '1px solid #3d3d3d', 
                                borderRadius: 1, 
                                p: 1, 
                                mb: 1, 
                                bgcolor: '#303030'
                              }}
                            >
                              <Typography variant="subtitle2" sx={{ color: '#00e676', fontWeight: 'bold' }}>
                                {book.name}
                              </Typography>
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                <Typography variant="body2" sx={{ color: '#fff' }}>
                                  <strong>Spread:</strong> {game[`${prefix}_awaySpread`] || 'N/A'} ({game[`${prefix}_awaySpreadOdds`] || 'N/A'}) Away / 
                                  {game[`${prefix}_homeSpread`] || 'N/A'} ({game[`${prefix}_homeSpreadOdds`] || 'N/A'}) Home
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#fff' }}>
                                  <strong>Moneyline:</strong> {game[`${prefix}_awayMLOdds`] || 'N/A'} (Away) / 
                                  {game[`${prefix}_homeMLOdds`] || 'N/A'} (Home)
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#fff' }}>
                                  <strong>Total:</strong> O {game[`${prefix}_totalOver`] || 'N/A'} ({game[`${prefix}_totalOverOdds`] || 'N/A'}) / 
                                  U {game[`${prefix}_totalUnder`] || 'N/A'} ({game[`${prefix}_totalUnderOdds`] || 'N/A'})
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#fff' }}>
                                  <strong>Implied Totals:</strong> {impliedTotals.away} (Away) / {impliedTotals.home} (Home)
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    );
                  }) : (
                    <Typography sx={{ color: '#fff' }}>No betting odds available for the selected slate.</Typography>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default OptimizerUI;