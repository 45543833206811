import React, { useState, useEffect } from "react";
import { Container, Typography, Button, FormControl, InputLabel, Select, MenuItem, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SelectSubscription = () => {
  const navigate = useNavigate();
  const [subscription] = useState("ALL"); // Fixed to ALL
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/user`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.subscription === "ALL") {
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.error("❌ Error fetching subscription data:", err);
        navigate("/login");
      });
  }, [navigate]);

  const handleSubscribe = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`,
        { subscription: "ALL" }, // No need to send email
        { withCredentials: true }
      );
      window.location.href = res.data.url;
    } catch (error) {
      setLoading(false);
      const errorMsg = error.response?.data?.error || "Payment initiation failed. Please try again.";
      setError(errorMsg);
      console.error("❌ Subscription Error:", error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", padding: "2rem 0" }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Select Your Subscription
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <FormControl fullWidth margin="normal" disabled>
        <InputLabel>Select Your Plan</InputLabel>
        <Select value={subscription}>
          <MenuItem value="ALL">All Sports - $7.99/month</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubscribe}
        disabled={loading}
        sx={{ mt: 2 }}
      >
        {loading ? "Processing..." : "Proceed to Payment"}
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={() => navigate("/dashboard")}
        sx={{ mt: 2 }}
      >
        Back to Dashboard
      </Button>
    </Container>
  );
};

export default SelectSubscription;