import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <Container maxWidth="md" sx={{ py: "4rem" }}>
      <Typography variant="h3" fontWeight="bold" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        Last Updated: March 04, 2025
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" color="text.secondary">
          At DFS Wizard, we value your privacy. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website and services (the "Service").
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" color="text.secondary">
          - <strong>Personal Information</strong>: We may collect your name, email address, and payment details when you sign up or contact us.<br />
          - <strong>Usage Data</strong>: We collect information like IP addresses, browser type, and pages visited to improve the Service.<br />
          - <strong>Cookies</strong>: We use cookies to enhance your experience. You can disable cookies, but some features may not work.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" color="text.secondary">
          - To provide and improve the Service (e.g., AI-driven DFS tools).<br />
          - To communicate with you (e.g., updates, support).<br />
          - To analyze usage and optimize performance.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          4. Sharing Your Information
        </Typography>
        <Typography variant="body1" color="text.secondary">
          - We do not sell your personal information.<br />
          - We may share data with third-party providers (e.g., payment processors, analytics) under strict confidentiality.<br />
          - We may disclose information if required by law.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          5. Data Security
        </Typography>
        <Typography variant="body1" color="text.secondary">
          We use reasonable measures to protect your information, but no online service is 100% secure.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          6. Your Rights
        </Typography>
        <Typography variant="body1" color="text.secondary">
          - You may request access, correction, or deletion of your data by emailing <strong>support@dfswizard.com</strong>.<br />
          - You may have additional rights under laws like GDPR or CCPA, depending on your location.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          7. Third-Party Links
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Our Service may link to external sites (e.g., DraftKings, FanDuel). We are not responsible for their privacy practices.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          8. Changes to This Policy
        </Typography>
        <Typography variant="body1" color="text.secondary">
          We may update this Privacy Policy. Changes will be posted here, and continued use signifies acceptance.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography variant="body1" color="text.secondary">
          For privacy inquiries, reach us at <strong>support@dfswizard.com</strong>.
        </Typography>
      </Box>

      <Typography variant="body2" color="text.secondary">
        Back to <Link to="/">Home</Link>
      </Typography>
    </Container>
  );
};

export default Privacy;