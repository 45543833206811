import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [gameDate, setGameDate] = useState("");
  const [seasonStartDate, setSeasonStartDate] = useState("");
  const [seasonEndDate, setSeasonEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [slateID, setSlateID] = useState("");
  const [projectionDate, setProjectionDate] = useState("");
  const [lastSlateID, setLastSlateID] = useState(null);
  const [slateDate, setSlateDate] = useState("");
  const [games, setGames] = useState([]);
  const [selectedGames, setSelectedGames] = useState([]);
  const [message, setMessage] = useState("");
  const [addPlayer, setAddPlayer] = useState(false);
  const [projectedOwnership, setProjectedOwnership] = useState("");
  const [initialQuestionable, setInitialQuestionable] = useState(""); // New state for initial questionable
  const [initialProjectedOwnership, setInitialProjectedOwnership] = useState(""); // New state for initial ownership

  // Player Management States
  const [playerSearchSlateID, setPlayerSearchSlateID] = useState("");
  const [playerSearchTerm, setPlayerSearchTerm] = useState("");
  const [playerOptions, setPlayerOptions] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [removePlayer, setRemovePlayer] = useState(false);
  const [questionable, setQuestionable] = useState("");

  // Fetch last slateID on mount
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/admin/last-slate-id`, { withCredentials: true })
      .then((res) => setLastSlateID(res.data.lastSlateID))
      .catch((err) => console.error("Error fetching last slate ID:", err));
  }, []);

  const handleScriptRun = async (endpoint, data = {}) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, data, {
        withCredentials: true,
      });
      setMessage(res.data.message);
    } catch (err) {
      const errorMessage =
        err.response?.data?.error +
          (err.response?.data?.details ? `: ${err.response.data.details}` : "") ||
        "An error occurred. Check server logs for details.";
      setMessage(errorMessage);
    }
  };

  const fetchGames = async () => {
    if (!slateDate || !/^\d{4}-\d{2}-\d{2}$/.test(slateDate)) {
      setMessage("Please enter a valid date (YYYY-MM-DD)");
      return;
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/games-for-date`,
        { date: slateDate },
        { withCredentials: true }
      );
      setGames(res.data.games);
      setSelectedGames([]);
      setMessage(`Found ${res.data.games.length} games for ${slateDate}`);
    } catch (err) {
      setMessage(err.response?.data?.error || "Error fetching games");
    }
  };

  const toggleGameSelection = (gameID) => {
    setSelectedGames((prev) =>
      prev.includes(gameID) ? prev.filter((id) => id !== gameID) : [...prev, gameID]
    );
  };

  const createSlate = () => {
    handleScriptRun("/admin/create-slate", {
      slateID: parseInt(slateID),
      date: slateDate,
      selectedGames,
    });
  };

  // Fetch players for autocomplete
  const fetchPlayers = async (term) => {
    if (!playerSearchSlateID || !term || term.length < 2) {
      setPlayerOptions([]);
      return;
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/search-players`,
        { searchTerm: term, slateID: parseInt(playerSearchSlateID) },
        { withCredentials: true }
      );
      setPlayerOptions(res.data.players);
    } catch (err) {
      console.error("Error fetching players:", err);
      setPlayerOptions([]);
    }
  };

  const handlePlayerUpdate = () => {
    if (!selectedPlayer) {
      setMessage("Please select a player");
      return;
    }
    handleScriptRun("/admin/update-player-projection", {
      slateID: parseInt(playerSearchSlateID),
      playerID: selectedPlayer.playerID,
      remove: removePlayer,
      add: addPlayer,
      questionable: questionable || null,
      projectedOwnership: projectedOwnership ? parseFloat(projectedOwnership) : null,
    });
    setSelectedPlayer(null);
    setRemovePlayer(false);
    setAddPlayer(false);
    setQuestionable("");
    setProjectedOwnership("");
    setInitialQuestionable(""); // Reset initial states
    setInitialProjectedOwnership("");
    setPlayerSearchTerm("");
  };

  return (
    <Container sx={{ bgcolor: "#1a1a1a", minHeight: "100vh", py: 4, color: "#fff" }}>
      <Typography
        variant="h4"
        fontWeight="bold"
        gutterBottom
        sx={{ color: "#00e676", textAlign: "center", textTransform: "uppercase" }}
      >
        Admin Dashboard
      </Typography>
      <Typography
        variant="h6"
        color="textSecondary"
        paragraph
        sx={{ color: "#b0b0b0", textAlign: "center", mb: 4 }}
      >
        Manage NBA Data Updates
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 4 }}>
        {/* NBA Player Details Update */}
        <Card
          sx={{
            bgcolor: "#2c2c2c",
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0,0,0,0.5)",
            transition: "all 0.3s ease",
            "&:hover": { boxShadow: "0 6px 25px rgba(0, 230, 118, 0.2)" },
            width: "100%",
            maxWidth: 600,
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Typography
              variant="h6"
              sx={{ color: "#00e676", fontWeight: "bold", mb: 2, textTransform: "uppercase" }}
            >
              Update Player Details
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleScriptRun("/admin/update-nba-player-details")}
              sx={{
                bgcolor: "#00e676",
                color: "#fff",
                fontWeight: "bold",
                px: 4,
                borderRadius: 2,
                boxShadow: "0 4px 15px rgba(0, 230, 118, 0.4)",
                transition: "all 0.3s ease",
                "&:hover": {
                  bgcolor: "#00c853",
                  boxShadow: "0 6px 20px rgba(0, 230, 118, 0.6)",
                },
              }}
            >
              Update NBA Player Details
            </Button>
          </CardContent>
        </Card>

        {/* NBA Player Stats Update */}
        <Card
          sx={{
            bgcolor: "#2c2c2c",
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0,0,0,0.5)",
            transition: "all 0.3s ease",
            "&:hover": { boxShadow: "0 6px 25px rgba(0, 230, 118, 0.2)" },
            width: "100%",
            maxWidth: 600,
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Typography
              variant="h6"
              sx={{ color: "#00e676", fontWeight: "bold", mb: 2, textTransform: "uppercase" }}
            >
              Update Player Stats
            </Typography>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <TextField
                label="Start Date (YYYY-MM-DD)"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                sx={{
                  "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff", borderRadius: 1 },
                  "& .MuiInputLabel-root": { color: "#b0b0b0" },
                  "& .Mui-focused .MuiInputLabel-root": { color: "#00e676" },
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#00e676" },
                }}
              />
              <Button
                variant="contained"
                onClick={() => handleScriptRun("/admin/update-nba-player-stats", { startDate })}
                disabled={!startDate || !/^\d{4}-\d{2}-\d{2}$/.test(startDate)}
                sx={{
                  bgcolor: "#00e676",
                  color: "#fff",
                  fontWeight: "bold",
                  px: 4,
                  borderRadius: 2,
                  boxShadow: "0 4px 15px rgba(0, 230, 118, 0.4)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    bgcolor: "#00c853",
                    boxShadow: "0 6px 20px rgba(0, 230, 118, 0.6)",
                  },
                }}
              >
                Update NBA Player Stats
              </Button>
            </Box>
          </CardContent>
        </Card>

        {/* NBA Betting Odds Update */}
        <Card
          sx={{
            bgcolor: "#2c2c2c",
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0,0,0,0.5)",
            transition: "all 0.3s ease",
            "&:hover": { boxShadow: "0 6px 25px rgba(0, 230, 118, 0.2)" },
            width: "100%",
            maxWidth: 600,
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Typography
              variant="h6"
              sx={{ color: "#00e676", fontWeight: "bold", mb: 2, textTransform: "uppercase" }}
            >
              Update Betting Odds
            </Typography>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <TextField
                label="Game Date (YYYYMMDD)"
                value={gameDate}
                onChange={(e) => setGameDate(e.target.value)}
                sx={{
                  "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff", borderRadius: 1 },
                  "& .MuiInputLabel-root": { color: "#b0b0b0" },
                  "& .Mui-focused .MuiInputLabel-root": { color: "#00e676" },
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#00e676" },
                }}
              />
              <Button
                variant="contained"
                onClick={() => handleScriptRun("/admin/update-nba-betting-odds", { gameDate })}
                disabled={!gameDate || !/^\d{8}$/.test(gameDate)}
                sx={{
                  bgcolor: "#00e676",
                  color: "#fff",
                  fontWeight: "bold",
                  px: 4,
                  borderRadius: 2,
                  boxShadow: "0 4px 15px rgba(0, 230, 118, 0.4)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    bgcolor: "#00c853",
                    boxShadow: "0 6px 20px rgba(0, 230, 118, 0.6)",
                  },
                }}
              >
                Update NBA Betting Odds
              </Button>
            </Box>
          </CardContent>
        </Card>

        {/* Create Slate */}
        <Card
          sx={{
            bgcolor: "#2c2c2c",
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0,0,0,0.5)",
            transition: "all 0.3s ease",
            "&:hover": { boxShadow: "0 6px 25px rgba(0, 230, 118, 0.2)" },
            width: "100%",
            maxWidth: 600,
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Typography
              variant="h6"
              sx={{ color: "#00e676", fontWeight: "bold", mb: 2, textTransform: "uppercase" }}
            >
              Create Slate
            </Typography>
            <Typography variant="body1" sx={{ color: "#b0b0b0", mb: 2 }}>
              Last Slate ID: {lastSlateID !== null ? lastSlateID : "Loading..."}
            </Typography>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
              <TextField
                label="Slate Date (YYYY-MM-DD)"
                value={slateDate}
                onChange={(e) => setSlateDate(e.target.value)}
                sx={{
                  "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff", borderRadius: 1 },
                  "& .MuiInputLabel-root": { color: "#b0b0b0" },
                  "& .Mui-focused .MuiInputLabel-root": { color: "#00e676" },
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#00e676" },
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={fetchGames}
                disabled={!slateDate || !/^\d{4}-\d{2}-\d{2}$/.test(slateDate)}
                sx={{
                  bgcolor: "#ff5722",
                  color: "#fff",
                  fontWeight: "bold",
                  px: 4,
                  borderRadius: 2,
                  boxShadow: "0 4px 15px rgba(255, 87, 34, 0.4)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    bgcolor: "#e64a19",
                    boxShadow: "0 6px 20px rgba(255, 87, 34, 0.6)",
                  },
                }}
              >
                Fetch Games
              </Button>
            </Box>
            {games.length > 0 && (
              <>
                <TextField
                  label="New Slate ID"
                  type="number"
                  value={slateID}
                  onChange={(e) => setSlateID(e.target.value)}
                  sx={{
                    mb: 2,
                    "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff", borderRadius: 1 },
                    "& .MuiInputLabel-root": { color: "#b0b0b0" },
                    "& .Mui-focused .MuiInputLabel-root": { color: "#00e676" },
                    "& .MuiOutlinedInput-notchedOutline": { borderColor: "#00e676" },
                  }}
                />
                <Box sx={{ textAlign: "left", maxHeight: 200, overflowY: "auto", mb: 2 }}>
                  {games.map((game) => (
                    <FormControlLabel
                      key={game.gameID}
                      control={
                        <Checkbox
                          checked={selectedGames.includes(game.gameID)}
                          onChange={() => toggleGameSelection(game.gameID)}
                          sx={{ color: "#00e676", "&.Mui-checked": { color: "#00e676" } }}
                        />
                      }
                      label={`${game.gameID} (${game.awayTeam} @ ${game.homeTeam})`}
                      sx={{ color: "#fff" }}
                    />
                  ))}
                </Box>
                <Button
                  variant="contained"
                  onClick={createSlate}
                  disabled={
                    !slateID ||
                    !Number.isInteger(parseInt(slateID)) ||
                    parseInt(slateID) < 1 ||
                    selectedGames.length === 0
                  }
                  sx={{
                    bgcolor: "#00e676",
                    color: "#fff",
                    fontWeight: "bold",
                    px: 4,
                    borderRadius: 2,
                    boxShadow: "0 4px 15px rgba(0, 230, 118, 0.4)",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      bgcolor: "#00c853",
                      boxShadow: "0 6px 20px rgba(0, 230, 118, 0.6)",
                    },
                  }}
                >
                  Create Slate
                </Button>
              </>
            )}
          </CardContent>
        </Card>

        {/* NBA Projections */}
        <Card
          sx={{
            bgcolor: "#2c2c2c",
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0,0,0,0.5)",
            transition: "all 0.3s ease",
            "&:hover": { boxShadow: "0 6px 25px rgba(0, 230, 118, 0.2)" },
            width: "100%",
            maxWidth: 600,
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Typography
              variant="h6"
              sx={{ color: "#00e676", fontWeight: "bold", mb: 2, textTransform: "uppercase" }}
            >
              Generate Projections
            </Typography>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <TextField
                label="Slate ID"
                type="number"
                value={slateID}
                onChange={(e) => setSlateID(e.target.value)}
                sx={{
                  "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff", borderRadius: 1 },
                  "& .MuiInputLabel-root": { color: "#b0b0b0" },
                  "& .Mui-focused .MuiInputLabel-root": { color: "#00e676" },
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#00e676" },
                }}
              />
              <TextField
                label="Date (YYYYMMDD)"
                value={projectionDate}
                onChange={(e) => setProjectionDate(e.target.value)}
                sx={{
                  "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff", borderRadius: 1 },
                  "& .MuiInputLabel-root": { color: "#b0b0b0" },
                  "& .Mui-focused .MuiInputLabel-root": { color: "#00e676" },
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#00e676" },
                }}
              />
              <Button
                variant="contained"
                onClick={() =>
                  handleScriptRun("/admin/generate-nba-projections", {
                    slateID: parseInt(slateID),
                    date: projectionDate,
                  })
                }
                disabled={
                  !slateID ||
                  !projectionDate ||
                  !/^\d{8}$/.test(projectionDate) ||
                  !Number.isInteger(parseInt(slateID)) ||
                  parseInt(slateID) < 1
                }
                sx={{
                  bgcolor: "#00e676",
                  color: "#fff",
                  fontWeight: "bold",
                  px: 4,
                  borderRadius: 2,
                  boxShadow: "0 4px 15px rgba(0, 230, 118, 0.4)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    bgcolor: "#00c853",
                    boxShadow: "0 6px 20px rgba(0, 230, 118, 0.6)",
                  },
                }}
              >
                Generate NBA Projections
              </Button>
            </Box>
          </CardContent>
        </Card>

        {/* Player Management */}
        <Card
          sx={{
            bgcolor: "#2c2c2c",
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0,0,0,0.5)",
            transition: "all 0.3s ease",
            "&:hover": { boxShadow: "0 6px 25px rgba(0, 230, 118, 0.2)" },
            width: "100%",
            maxWidth: 600,
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Typography
              variant="h6"
              sx={{ color: "#00e676", fontWeight: "bold", mb: 2, textTransform: "uppercase" }}
            >
              Player Management
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TextField
                label="Slate ID"
                type="number"
                value={playerSearchSlateID}
                onChange={(e) => setPlayerSearchSlateID(e.target.value)}
                sx={{
                  "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff", borderRadius: 1 },
                  "& .MuiInputLabel-root": { color: "#b0b0b0" },
                  "& .Mui-focused .MuiInputLabel-root": { color: "#00e676" },
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#00e676" },
                }}
              />
              <Autocomplete
                options={playerOptions}
                getOptionLabel={(option) => option.playerName}
                onInputChange={(e, value) => {
                  setPlayerSearchTerm(value);
                  fetchPlayers(value);
                }}
                onChange={(e, value) => {
                  setSelectedPlayer(value);
                  setRemovePlayer(false);
                  setAddPlayer(false);
                  setQuestionable(value?.questionable || ""); // Load current questionable status
                  setProjectedOwnership(value?.projectedOwnership || "");
                  setInitialQuestionable(value?.questionable || ""); // Set initial values
                  setInitialProjectedOwnership(value?.projectedOwnership || "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Player"
                    sx={{
                      "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff", borderRadius: 1 },
                      "& .MuiInputLabel-root": { color: "#b0b0b0" },
                      "& .Mui-focused .MuiInputLabel-root": { color: "#00e676" },
                      "& .MuiOutlinedInput-notchedOutline": { borderColor: "#00e676" },
                    }}
                  />
                )}
                disabled={!playerSearchSlateID || !Number.isInteger(parseInt(playerSearchSlateID))}
              />
              {selectedPlayer && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={removePlayer}
                        onChange={(e) => {
                          setRemovePlayer(e.target.checked);
                          if (e.target.checked) setAddPlayer(false);
                        }}
                        sx={{ color: "#00e676", "&.Mui-checked": { color: "#00e676" } }}
                      />
                    }
                    label={`Remove ${selectedPlayer.playerName} from slate ${playerSearchSlateID}`}
                    sx={{ color: "#fff" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addPlayer}
                        onChange={(e) => {
                          setAddPlayer(e.target.checked);
                          if (e.target.checked) setRemovePlayer(false);
                        }}
                        sx={{ color: "#00e676", "&.Mui-checked": { color: "#00e676" } }}
                      />
                    }
                    label={`Add ${selectedPlayer.playerName} to slate ${playerSearchSlateID}`}
                    sx={{ color: "#fff" }}
                  />
                  <InputLabel sx={{ color: "#b0b0b0" }}>Questionable Status</InputLabel>
                  <Select
                    value={questionable}
                    onChange={(e) => setQuestionable(e.target.value)}
                    sx={{
                      "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff", borderRadius: 1 },
                      "& .MuiOutlinedInput-notchedOutline": { borderColor: "#00e676" },
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="P">P</MenuItem>
                    <MenuItem value="Q">Q</MenuItem>
                  </Select>
                  <TextField
                    label="Projected Ownership (%)"
                    type="number"
                    value={projectedOwnership}
                    onChange={(e) => setProjectedOwnership(e.target.value)}
                    inputProps={{ min: 0, max: 100, step: 0.1 }}
                    sx={{
                      "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff", borderRadius: 1 },
                      "& .MuiInputLabel-root": { color: "#b0b0b0" },
                      "& .Mui-focused .MuiInputLabel-root": { color: "#00e676" },
                      "& .MuiOutlinedInput-notchedOutline": { borderColor: "#00e676" },
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={handlePlayerUpdate}
                    disabled={
                      !removePlayer &&
                      !addPlayer &&
                      questionable === initialQuestionable &&
                      projectedOwnership === initialProjectedOwnership
                    }
                    sx={{
                      bgcolor: "#00e676",
                      color: "#fff",
                      fontWeight: "bold",
                      px: 4,
                      borderRadius: 2,
                      boxShadow: "0 4px 15px rgba(0, 230, 118, 0.4)",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        bgcolor: "#00c853",
                        boxShadow: "0 6px 20px rgba(0, 230, 118, 0.6)",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>

        {/* NBA Games Update */}
        <Card
          sx={{
            bgcolor: "#2c2c2c",
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0,0,0,0.5)",
            transition: "all 0.3s ease",
            "&:hover": { boxShadow: "0 6px 25px rgba(0, 230, 118, 0.2)" },
            width: "100%",
            maxWidth: 600,
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Typography
              variant="h6"
              sx={{ color: "#00e676", fontWeight: "bold", mb: 2, textTransform: "uppercase" }}
            >
              Update Games
            </Typography>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <TextField
                label="Season Start Date (YYYYMMDD)"
                value={seasonStartDate}
                onChange={(e) => setSeasonStartDate(e.target.value)}
                sx={{
                  "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff", borderRadius: 1 },
                  "& .MuiInputLabel-root": { color: "#b0b0b0" },
                  "& .Mui-focused .MuiInputLabel-root": { color: "#00e676" },
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#00e676" },
                }}
              />
              <TextField
                label="Season End Date (YYYYMMDD)"
                value={seasonEndDate}
                onChange={(e) => setSeasonEndDate(e.target.value)}
                sx={{
                  "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff", borderRadius: 1 },
                  "& .MuiInputLabel-root": { color: "#b0b0b0" },
                  "& .Mui-focused .MuiInputLabel-root": { color: "#00e676" },
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#00e676" },
                }}
              />
              <Button
                variant="contained"
                onClick={() =>
                  handleScriptRun("/admin/update-nba-games", { seasonStartDate, seasonEndDate })
                }
                disabled={
                  !seasonStartDate ||
                  !seasonEndDate ||
                  !/^\d{8}$/.test(seasonStartDate) ||
                  !/^\d{8}$/.test(seasonEndDate)
                }
                sx={{
                  bgcolor: "#00e676",
                  color: "#fff",
                  fontWeight: "bold",
                  px: 4,
                  borderRadius: 2,
                  boxShadow: "0 4px 15px rgba(0, 230, 118, 0.4)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    bgcolor: "#00c853",
                    boxShadow: "0 6px 20px rgba(0, 230, 118, 0.6)",
                  },
                }}
              >
                Update NBA Games
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {message && (
        <Typography
          variant="body1"
          color={message.includes("error") ? "error" : "success"}
          sx={{ mt: 4, textAlign: "center" }}
        >
          {message}
        </Typography>
      )}

      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate("/dashboard")}
          sx={{
            borderColor: "#00e676",
            color: "#00e676",
            fontWeight: "bold",
            px: 4,
            borderRadius: 2,
            transition: "all 0.3s ease",
            "&:hover": {
              bgcolor: "#00e676",
              color: "#fff",
              borderColor: "#00e676",
            },
          }}
        >
          Back to Dashboard
        </Button>
      </Box>
    </Container>
  );
};

export default AdminDashboard;