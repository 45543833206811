import React from "react";
import { Container, Typography, Button, Grid, Card, CardContent, Box, Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { AutoGraph, Update, SportsFootball, Savings, People, PhoneAndroid } from "@mui/icons-material";
import { Helmet } from "react-helmet";

const Landing = () => {
  return (
    <>
      {/* Reddit Pixel via Helmet */}
      <Helmet>
        <script>
          {`
            !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
            rdt('init','a2_gingzfqt9j7r');
            rdt('track', 'PageVisit');
          `}
        </script>
      </Helmet>
      <Container maxWidth="lg" sx={{ textAlign: "center", py: "5rem", background: "linear-gradient(135deg, #f5f7fa 0%, #e0e7ff 100%)" }}>
        {/* Hero Section */}
        <Box
          sx={{
            mb: 8,
            py: 6,
            px: 3,
            background: "linear-gradient(135deg, rgba(63, 81, 181, 0.9) 0%, rgba(26, 35, 126, 0.9) 100%), url('/hero-bg.jpg') center/cover no-repeat",
            borderRadius: 3,
            boxShadow: "0 8px 24px rgba(0, 0, 0, 0.2)",
            color: "#fff",
            animation: "fadeIn 1s ease-in-out",
            "@keyframes fadeIn": {
              "0%": { opacity: 0, transform: "translateY(20px)" },
              "100%": { opacity: 1, transform: "translateY(0)" },
            },
          }}
        >
          <Typography
            variant="h1"
            fontWeight="bold"
            gutterBottom
            sx={{
              letterSpacing: "-1px",
              fontSize: { xs: "1.8rem", md: "4rem" },
              background: "linear-gradient(45deg, #ffeb3b, #ff5722)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              display: "inline-block",
            }}
          >
            DFS Wizard:
            <Typography
              component="span"
              variant="h1"
              fontWeight="bold"
              sx={{ color: "#fff", ml: 1, fontSize: { xs: "1.8rem", md: "4rem" } }}
            >
              The Projections Were Too Good to Share
            </Typography>
          </Typography>
          <Typography
            variant="h5"
            sx={{
              maxWidth: "800px",
              mx: "auto",
              mb: 4,
              fontSize: { xs: "0.9rem", md: "1.5rem" },
              fontWeight: 300,
              opacity: 0.9,
            }}
          >
            Once upon a time, my NBA, NFL, and MLB projections were so spot-on, I had to hide them from the world. Here’s why.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component={Link}
            to="/login"
            sx={{ px: 5, py: 2, fontWeight: "bold", borderRadius: 10, background: "#fff", color: "#1a237e", "&:hover": { background: "#e0e7ff" } }}
          >
            Log In (If I Let You)
          </Button>
        </Box>

        {/* Backstory Section */}
        <Box sx={{ mb: 10, px: 3 }}>
          <Typography variant="h4" fontWeight="bold" gutterBottom sx={{ mb: 3, color: "#1a237e" }}>
            The Day I Broke DFS and Hid the Evidence
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ maxWidth: "900px", mx: "auto" }}>
            Picture this: I’d been grinding Daily Fantasy Sports for over a decade—NBA dunks, NFL touchdowns, MLB home runs, the works. I’d tinkered with every tool under the sun, from RotoGrinders to DFS Army, but I always knew I could do better. So, I built DFS Wizard, a lean, mean projection machine that started spitting out lineups so golden they’d make King Midas jealous. One fateful Sunday, I entered a $5 NFL contest on DraftKings with a lineup my optimizer coughed up. By halftime, I’d already lapped the field—my running back had three touchdowns, my wide receiver was moonwalking into the end zone, and my defense was picking off passes like they were auditioning for a heist movie. By Monday morning, I’d won $50,000. No big deal, right? Wrong. The next week, I did it again—$75,000. Then $100,000. People started whispering, “Who’s this DFS Wizard guy?” DraftKings sent me a polite email asking if I was a robot. I wasn’t, but my projections were so good they might as well have been coded by Skynet. The final straw? I swept an entire NBA slate, every single lineup in the top 10 was mine, and the prize pool looked like my personal piggy bank. That’s when I knew: these projections were too powerful. I couldn’t let them loose on the world—Vegas would go broke, DFS sites would ban me, and my inbox would be flooded with fan mail from guys named Chad asking for “just one lineup, bro.” So, I pulled the plug. DFS Wizard went dark. Now, only me and a select few I’ve personally blessed can log in. The rest of you? Good luck with your gut picks and your cousin’s “hot tips.”
          </Typography>
        </Box>

        {/* Features Section */}
        <Typography variant="h4" fontWeight="bold" gutterBottom sx={{ mb: 5, color: "#1a237e" }}>
          Why DFS Wizard Was Too Good
        </Typography>
        <Grid container spacing={4} sx={{ mb: 10 }}>
          {[
            {
              title: "Pro-Level Projections",
              description: "My dual-layer system blended stats and simulations so well, I once predicted LeBron would sneeze mid-game—and he did.",
              icon: <AutoGraph sx={{ fontSize: 40, color: "#3f51b5" }} />
            },
            {
              title: "Real-Time Updates",
              description: "Injuries, lineup changes—I knew before the coaches did. My app once texted Popovich about Kawhi’s load management.",
              icon: <Update sx={{ fontSize: 40, color: "#3f51b5" }} />
            },
            {
              title: "Multi-Sport Power",
              description: "NBA, NFL, MLB—my tools crushed them all. I once won a parlay betting on my own projections across three sports.",
              icon: <SportsFootball sx={{ fontSize: 40, color: "#3f51b5" }} />
            },
            {
              title: "Half the Effort, Full Domination",
              description: "While others slaved over spreadsheets, I sipped coffee and watched my optimizer print money.",
              icon: <Savings sx={{ fontSize: 40, color: "#3f51b5" }} />
            },
            {
              title: "Player-Friendly Design",
              description: "So intuitive, my grandma used it to win a FanDuel contest—then asked me to hide it from her bingo club.",
              icon: <People sx={{ fontSize: 40, color: "#3f51b5" }} />
            },
            {
              title: "Mobile Mastery",
              description: "I optimized lineups from my phone while stuck in traffic—won $10K before the light turned green.",
              icon: <PhoneAndroid sx={{ fontSize: 40, color: "#3f51b5" }} />
            }
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  height: "100%",
                  boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: 3,
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": { transform: "scale(1.05)", boxShadow: "0 12px 30px rgba(0, 0, 0, 0.15)" },
                  background: "#fff"
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    {feature.icon}
                    <Typography variant="h6" fontWeight="bold" color="primary" sx={{ ml: 2 }}>
                      {feature.title}
                    </Typography>
                  </Box>
                  <Typography variant="body1" color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Optimizer Screenshots Section */}
        <Box sx={{ mb: 10, py: 4, background: "rgba(255, 255, 255, 0.85)", borderRadius: 3, boxShadow: 3 }}>
          <Typography variant="h4" fontWeight="bold" gutterBottom sx={{ mb: 5, color: "#1a237e" }}>
            See the Magic I Took Away
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <Card sx={{ boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)", borderRadius: 3 }}>
                <CardContent sx={{ p: 3 }}>
                  <img
                    src="/ScreenshotOptimizer.jpg"
                    alt="Optimizer Interface"
                    style={{ width: "100%", maxWidth: "100%", height: "auto", borderRadius: 8 }}
                  />
                  <Typography variant="h6" fontWeight="bold" color="primary" sx={{ mt: 2, mb: 1 }}>
                    Optimizer Interface
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    A clean, mobile-ready optimizer that was so good, I once caught it trash-talking other tools.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card sx={{ boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)", borderRadius: 3 }}>
                <CardContent sx={{ p: 3 }}>
                  <img
                    src="/ScreenshotOptimizerResults.jpg"
                    alt="Lineup Results"
                    style={{ width: "100%", maxWidth: "100%", height: "auto", borderRadius: 8 }}
                  />
                  <Typography variant="h6" fontWeight="bold" color="primary" sx={{ mt: 2, mb: 1 }}>
                    Lineup Results
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Detailed outputs so accurate, I had to hide them before the IRS asked questions.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card sx={{ boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)", borderRadius: 3 }}>
                <CardContent sx={{ p: 3 }}>
                  <img
                    src="/ScreenshotBettingData.jpg"
                    alt="Betting Odds"
                    style={{ width: "100%", maxWidth: "100%", height: "auto", borderRadius: 8 }}
                  />
                  <Typography variant="h6" fontWeight="bold" color="primary" sx={{ mt: 2, mb: 1 }}>
                    Betting Odds
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Real-time BetMGM odds so sharp, bookies started sending me fruit baskets to stop.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card sx={{ boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)", borderRadius: 3 }}>
                <CardContent sx={{ p: 3 }}>
                  <img
                    src="/ScreenshotNews.jpg"
                    alt="NBA News"
                    style={{ width: "100%", maxWidth: "100%", height: "auto", borderRadius: 8 }}
                  />
                  <Typography variant="h6" fontWeight="bold" color="primary" sx={{ mt: 2, mb: 1 }}>
                    News Updates
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Latest injuries and roster news—too good, I hid it before ESPN hired me.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Testimonials Section */}
        <Box sx={{ mb: 10 }}>
          <Typography variant="h4" fontWeight="bold" gutterBottom sx={{ mb: 5, color: "#1a237e" }}>
            What My Inner Circle Says
          </Typography>
          <Grid container spacing={4}>
            {[
              {
                name: "Jake T., My Best Friend",
                quote: "I saw his projections once. Won $10K, then he locked me out. Said I’d ruin it for everyone.",
                avatar: "JT"
              },
              {
                name: "Chris M., My Cousin",
                quote: "He let me use it for a week. I swept a slate, then he changed the password. Rude.",
                avatar: "CM"
              },
              {
                name: "Lisa R., My Sister",
                quote: "I crushed a contest on my phone, then he said, ‘That’s enough, sis.’ Now I’m back to bingo.",
                avatar: "LR"
              }
            ].map((testimonial, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card sx={{ boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)", borderRadius: 3 }}>
                  <CardContent sx={{ p: 3 }}>
                    <Avatar sx={{ bgcolor: "primary.main", mb: 2, width: 50, height: 50 }}>{testimonial.avatar}</Avatar>
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                      "{testimonial.quote}"
                    </Typography>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {testimonial.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Pricing Section (Repurposed) */}
        <Box sx={{ background: "linear-gradient(135deg, #3f51b5 0%, #1a237e 100%)", py: 8, borderRadius: 3, mb: 8, color: "#fff" }}>
          <Typography variant="h3" fontWeight="bold" gutterBottom>
            DFS Wizard: The Legend Lives On
          </Typography>
          <Typography variant="h6" sx={{ mb: 4, opacity: 0.9 }}>
            I could’ve made millions letting everyone in, but nah—these projections are my secret sauce now. Only me and my chosen few get to bask in their glory. Sorry, world.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="/login"
            sx={{ px: 5, py: 2, fontWeight: "bold", borderRadius: 10, boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)" }}
          >
            Log In (If You’re Lucky)
          </Button>
        </Box>

        {/* Trust & Urgency Section */}
        <Box sx={{ mb: 6 }}>
          <Typography variant="h5" fontWeight="bold" color="#1a237e" gutterBottom>
            Don’t Beg Me for Access
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            With over a decade of DFS mastery, I built something so good I had to lock it away. It’s not about gatekeeping—it’s about protecting the universe from my unstoppable edge. You’ll just have to trust me on this one.
          </Typography>
          <Typography variant="body2" color="primary" sx={{ fontStyle: "italic" }}>
            Maybe one day I’ll share it again. But probably not.
          </Typography>
        </Box>

        {/* Contact Section */}
        <Typography variant="body1" color="text.secondary">
          Questions? Reach out at <strong>support@dfswizard.com</strong>.
        </Typography>

        {/* Disclaimer */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="body2" color="text.secondary">
            <strong>Disclaimer:</strong> Daily Fantasy Sports carries risk; no outcomes are guaranteed. Access to DFS Wizard is by invite only, controlled by me, the wizard himself. By using it, you agree to our <Link to="/terms">Terms & Conditions</Link> and <Link to="/privacy">Privacy Policy</Link>.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default Landing;