// src/authContext.js
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState(null); // Add user state

  const checkAuth = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user`, {
        withCredentials: true,
      });
    //  console.log("checkAuth response:", res.data); // Debug log
      setIsAuthenticated(true);
      setUser({
        subscription: res.data.subscription,
        status: res.data.status,
        role: res.data.role || "user",
      });
      return res.data;
    } catch (err) {
      console.error("Auth check failed:", err);
      setIsAuthenticated(false);
      setUser(null);
      return null;
    }
  };

  useEffect(() => {
    checkAuth(); // Check auth on app load
  }, []);

  const login = async (formData) => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/login`,
      formData,
      { withCredentials: true }
    );
    await checkAuth(); // Update auth state and user after login
    return res.data;
  };

  const logout = async () => {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/logout`, {}, { withCredentials: true });
    setIsAuthenticated(false);
    setUser(null); // Clear user data
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, checkAuth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};