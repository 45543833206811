// Dashboard.js
import React, { useContext } from "react";
import { Container, Typography, Button, Grid, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../authContext";

const Dashboard = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useContext(AuthContext);

  const sports = [
    { name: "NBA", route: "/optimizer" },
    { name: "NFL", route: "/optimizernfl" },
    { name: "MLB", route: "/optimizermlb" },
  ];

  const hasOptimizerAccess = user?.subscription === "ALL";
  const trialActive = user?.trial_expires_at && new Date(user.trial_expires_at) > new Date();

  if (isAuthenticated === null) {
    return <Container><Typography>Loading...</Typography></Container>;
  }

  return (
    <Container maxWidth="md" sx={{ textAlign: "center", padding: "4rem 0" }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Welcome to Your DFS Dashboard
      </Typography>
      {trialActive && (
        <Typography variant="h6" color="primary" paragraph>
          Your 3-day free trial ends on {new Date(user.trial_expires_at).toLocaleDateString()}.
        </Typography>
      )}
      {hasOptimizerAccess ? (
        <>
          <Typography variant="h6" color="textSecondary" paragraph>
            Select a sport to start optimizing your DFS lineups.
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {sports.map((sport) => (
              <Grid item xs={12} sm={4} key={sport.name}>
                <Card sx={{ cursor: "pointer" }} onClick={() => navigate(sport.route)}>
                  <CardContent>
                    <Typography variant="h5" fontWeight="bold">{sport.name}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Typography variant="h6" color="error" paragraph>
            Your trial has ended or you don’t have an active subscription. Subscribe now!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/select-subscription")}
            sx={{ mt: 2 }}
          >
            Subscribe Now
          </Button>
        </>
      )}
      {user?.role === "admin" && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate("/admin")}
          sx={{ mt: 4, mr: 2 }}
        >
          Admin Dashboard
        </Button>
      )}
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/settings")}
        sx={{ mt: 4 }}
      >
        Account Settings
      </Button>
      <Button
        variant="outlined"
        color="error"
        onClick={() => navigate("/logout")}
        sx={{ mt: 4, ml: 2 }}
      >
        Logout
      </Button>
    </Container>
  );
};

export default Dashboard;