import React, { useState, useContext } from "react";
import { Container, Typography, TextField, Button, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../authContext";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    //console.log("Form submitted:", formData);
    try {
      const res = await login(formData);
      //console.log("Login response:", res);
      navigate("/dashboard", { replace: true });
      //console.log("Navigation triggered");
    } catch (err) {
      console.error("Login error:", err);
      setError(err.response?.data?.error || "Invalid email or password. Please try again.");
    }
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", padding: "2rem 0" }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Login to Your DFS Account
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleLogin}>
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          type="password"
          label="Password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          sx={{ mt: 2 }}
        >
          Log In
        </Button>
      </form>
    </Container>
  );
};

export default Login;