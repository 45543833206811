// Signup.js
import React, { useState } from "react";
import { Container, Typography, TextField, Button, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignup = async () => {
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match. Please try again.");
      return;
    }

    try {
      setLoading(true);
      setError("");
      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register`, {
        email: formData.email,
        password: formData.password,
      });
      console.log("✅ Signup Response:", res.data);
      setLoading(false);
      
      // Redirect to Stripe Checkout
      if (res.data.url) {
        window.location.href = res.data.url;
      }
    } catch (err) {
      setLoading(false);
      const errorMsg = err.response?.data?.error || "Signup failed. Please try again.";
      setError(errorMsg);
      console.error("❌ Signup Error:", err.response ? err.response.data : err.message);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", padding: "2rem 0" }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Start Your 3-Day Free Trial
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
        Get full access to DFS Wizard for 3 days free. After that, it’s just $7.99/month. Cancel anytime.
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <TextField
        fullWidth
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        type="password"
        label="Password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        type="password"
        label="Confirm Password"
        name="confirmPassword"
        value={formData.confirmPassword}
        onChange={handleChange}
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSignup}
        disabled={loading}
        sx={{ mt: 2 }}
      >
        {loading ? "Processing..." : "Start Free Trial"}
      </Button>
    </Container>
  );
};

export default Signup;