import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <Container maxWidth="md" sx={{ py: "4rem" }}>
      <Typography variant="h3" fontWeight="bold" gutterBottom>
        Terms & Conditions
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        Last Updated: March 04, 2025
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Welcome to DFS Wizard. These Terms and Conditions govern your use of our website and services (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these terms. If you do not agree, please do not use the Service.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          2. Eligibility
        </Typography>
        <Typography variant="body1" color="text.secondary">
          You must be at least 18 years old (or the age of majority in your jurisdiction) to use this Service. By using DFS Wizard, you confirm that you meet this requirement.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          3. Use of the Service
        </Typography>
        <Typography variant="body1" color="text.secondary">
          - You agree not to use the Service for any illegal or unauthorized purpose.<br />
          - All purchases are non-refundable.<br />
          - We reserve the right to modify, suspend, or terminate the Service at any time without prior notice.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          4. Intellectual Property
        </Typography>
        <Typography variant="body1" color="text.secondary">
          All content on the Service, including text, images, and algorithms, is owned by DFS Wizard or its licensors and is protected by copyright and other laws. You may not reproduce, distribute, or modify any content without prior written permission.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          5. Disclaimer
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Daily Fantasy Sports involves risk, and no specific outcomes or winnings are guaranteed. DFS Wizard provides tools and data for entertainment purposes only. We are not responsible for any financial losses incurred through the use of our Service.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          6. Limitation of Liability
        </Typography>
        <Typography variant="body1" color="text.secondary">
          The Service is provided "as is" without warranties of any kind. DFS Wizard is not liable for any damages arising from your use of the Service, including direct, indirect, or consequential damages.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          7. Termination
        </Typography>
        <Typography variant="body1" color="text.secondary">
          We may terminate or suspend your access to the Service at our discretion, with or without cause, and without notice.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          8. Changes to Terms
        </Typography>
        <Typography variant="body1" color="text.secondary">
          We may update these Terms and Conditions from time to time. Continued use of the Service after changes constitutes acceptance of the new terms.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          9. Governing Law
        </Typography>
        <Typography variant="body1" color="text.secondary">
          These terms are governed by the laws of the United States. Any disputes will be resolved in the courts of [Your Jurisdiction—e.g., Delaware, if US-based].
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" fontWeight="medium" gutterBottom>
          10. Contact Us
        </Typography>
        <Typography variant="body1" color="text.secondary">
          For questions about these Terms, contact us at <strong>support@dfswizard.com</strong>.
        </Typography>
      </Box>

      <Typography variant="body2" color="text.secondary">
        Back to <Link to="/">Home</Link>
      </Typography>
    </Container>
  );
};

export default Terms;