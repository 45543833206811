import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Container, Typography, Button, Alert, Box, CircularProgress } from "@mui/material";

const Success = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");
  const [error, setError] = useState("");
  const [isVerified, setIsVerified] = useState(false); // Track verification completion

  useEffect(() => {
    if (sessionId) {
      console.log("✅ Payment Successful - Session ID:", sessionId);
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/verify-payment`, { sessionId })
        .then(() => {
          console.log("✅ Subscription Updated in Database");
          setIsVerified(true); // Mark verification as complete
        })
        .catch((error) => {
          const errorMsg = error.response?.data?.error || "Payment verification failed. Please contact support.";
          setError(errorMsg);
          console.error("❌ Payment Verification Error:", error);
        });
    } else {
      setError("No session ID found. Payment verification cannot proceed.");
    }
  }, [sessionId]);

  const handleGetStarted = () => {
    navigate("/dashboard");
  };

  return (
    <Container style={{ textAlign: "center", marginTop: "50px" }}>
      <Typography variant="h4">🎉 Payment Successful!</Typography>
      {error ? (
        <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>
      ) : isVerified ? (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" gutterBottom>
            Welcome to DFS Wizard!
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ maxWidth: "600px", mx: "auto", mb: 3 }}>
            You’re all set to start winning. Use the dashboard to select a sport and optimize your lineups with our AI-driven tools. Need help? Check out our support at support@dfswizard.com.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGetStarted}
            sx={{ px: 4, py: 1.5 }}
          >
            Get Started
          </Button>
        </Box>
      ) : (
        <>
          <Typography variant="body1">Verifying your payment...</Typography>
          <CircularProgress style={{ marginTop: "20px" }} />
        </>
      )}
    </Container>
  );
};

export default Success;