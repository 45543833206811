import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Container, Typography, Alert, CircularProgress } from "@mui/material";

const Verify = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/verify-email?token=${token}`)
        .then((res) => {
          setMessage(res.data.message);
          setTimeout(() => navigate("/login"), 3000);
        })
        .catch((err) => {
          const errorMsg = err.response?.data?.error || "Verification failed.";
          setError(errorMsg);
          console.error("❌ Verification Error:", err);
        });
    } else {
      setError("No verification token provided.");
    }
  }, [token, navigate]);

  return (
    <Container sx={{ textAlign: "center", mt: 5 }}>
      <Typography variant="h4" gutterBottom>Email Verification</Typography>
      {message && <Typography variant="body1">{message}</Typography>}
      {error && <Alert severity="error">{error}</Alert>}
      {message && !error && <CircularProgress sx={{ mt: 2 }} />}
    </Container>
  );
};

export default Verify;