import React, { useState } from "react";
import { Container, Typography, TextField, Button } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom"; 

const Settings = () => {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
 

  const handleChangePassword = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update-password`, {
        currentPassword,
        newPassword,
      }, {
        headers: { Authorization: `Bearer ${token}` },
		withCredentials: true,
      });
      alert("Password updated successfully!");
    } catch (error) {
      console.error("Password Change Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    if (!window.confirm("Are you sure you want to cancel your subscription? You will retain access until the end of your billing cycle.")) {
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/cancel-subscription`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert("Your subscription has been canceled. You will retain access until the end of the billing cycle.");
    } catch (error) {
      console.error("Subscription Cancellation Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", padding: "2rem 0" }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Account Settings
      </Typography>

      {/* Change Password Section */}
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Change Password
      </Typography>
      <TextField
        fullWidth
        type="password"
        label="Current Password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        type="password"
        label="New Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        margin="normal"
      />
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={handleChangePassword}
        disabled={loading}
        sx={{ mt: 2 }}
      >
        {loading ? "Updating..." : "Update Password"}
      </Button>

      {/* Cancel Subscription Section */}
      <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ mt: 4 }}>
        Manage Subscription
      </Typography>
      <Button
        variant="outlined"
        color="error"
        fullWidth
        onClick={handleCancelSubscription}
        disabled={loading}
        sx={{ mt: 2 }}
      >
        {loading ? "Processing..." : "Cancel Subscription"}
      </Button>
	  <Button
		  variant="outlined"
		  color="secondary"
		  fullWidth
		  onClick={() => navigate("/dashboard")}
		  sx={{ mt: 2 }}
		>
		  Back to Dashboard
		</Button>
    </Container>
  );
};

export default Settings;
