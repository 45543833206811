import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AuthProvider, AuthContext } from "./authContext";
import Landing from "./pages/landing";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Success from "./pages/Success";
import Dashboard from "./pages/Dashboard";
import OptimizerUI from "./pages/optimizerUI";
import OptimizerUImlb from "./pages/optimizerUImlb";
import OptimizerUInfl from "./pages/optimizerUInfl"; // New import
import SelectSubscription from "./pages/SelectSubscription";
import Settings from "./pages/Settings";
import Logout from "./pages/Logout";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import { CircularProgress } from "@mui/material";
import { useContext } from "react";
import Verify from "./pages/Verify";
import AdminDashboard from "./pages/adminDashboard";

// 🔒 Protected Route Component
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  if (isAuthenticated === null) return <CircularProgress />;
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const AdminRoute = ({ children }) => {
  const { isAuthenticated, user } = useContext(AuthContext);
  if (isAuthenticated === null) return <CircularProgress />;
  if (!isAuthenticated) return <Navigate to="/login" />;
  if (user?.role !== "admin") return <Navigate to="/dashboard" />;
  return children;
};
// Component to handle Google Analytics tracking
const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Initialize Google Analytics (gtag) if not already initialized
    if (!window.gtag) {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag("js", new Date());
      window.gtag("config", "AW-16908259587", {
        page_path: location.pathname + location.search,
      });
    } else {
      // Track page view on route change
      window.gtag("config", "AW-16908259587", {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]); // Re-run effect when location changes

  return null; // This component doesn’t render anything
};

const App = () => {
  useEffect(() => {
    // Dynamically inject the Google Tag script into the <head>
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-16908259587";
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16908259587');
    `;
    document.head.appendChild(script2);

    // Cleanup to remove scripts when component unmounts (optional)
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []); // Run only once on mount

  return (
    <AuthProvider>
      <Router>
        <AnalyticsTracker /> {/* Add the tracker here */}
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Landing />} /> {/* Changed to root */}
          <Route path="/landing" element={<Landing />} /> {/* Kept as optional */}
          <Route path="/verify" element={<Verify />} />
          <Route path="/success" element={<Success />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          {/* Removed: <Route path="/" element={<Navigate to="/landing" />} /> */}

          {/* 🔒 Protected Routes */}
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/optimizer" element={<ProtectedRoute><OptimizerUI /></ProtectedRoute>} />
          <Route path="/optimizermlb" element={<ProtectedRoute><OptimizerUImlb /></ProtectedRoute>} />
		  <Route path="/optimizernfl" element={<ProtectedRoute><OptimizerUInfl /></ProtectedRoute>} />
          <Route path="/select-subscription" element={<ProtectedRoute><SelectSubscription /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
		  <Route path="/admin" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;