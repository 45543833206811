import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container, Typography, Select, MenuItem, TextField, Button, Table, TableHead, TableRow,
  TableCell, TableBody, Card, CardContent, Tabs, Tab, Box, Slider, Chip, IconButton
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const getAvailablePosition = (player, currentLineup, site) => {
  const positions = player.position.split('/').map(p => p.toUpperCase());
  const dkPositions = ['P_1', 'P_2', 'C', '1B', '2B', '3B', 'SS', 'OF_1', 'OF_2', 'OF_3'];
  const fdPositions = ['P', 'C', '1B', '2B', '3B', 'SS', 'OF_1', 'OF_2', 'UTIL'];
  const availablePositions = site === 'dk' ? dkPositions : fdPositions;

  for (const pos of positions) {
    let slot;
    if (pos === 'P') {
      slot = currentLineup['P_1'] ? 'P_2' : 'P_1';
    } else if (['LF', 'RF', 'CF', 'OF'].includes(pos)) { // Add 'OF' here
      slot = !currentLineup['OF_1'] ? 'OF_1' : !currentLineup['OF_2'] ? 'OF_2' : (site === 'dk' && !currentLineup['OF_3'] ? 'OF_3' : null);
    } else {
      slot = pos;
    }

    if (slot && availablePositions.includes(slot) && !currentLineup[slot]) {
      return slot;
    }
  }

  // Handle FanDuel UTIL slot as a fallback for eligible players
  if (site === 'fd' && !currentLineup['UTIL'] && availablePositions.includes('UTIL')) {
    return 'UTIL';
  }

  return null;
};

const MLBOptimizerUI = () => {
  const navigate = useNavigate();
  const [slates, setSlates] = useState([]);
  const [siteSelection, setSiteSelection] = useState(localStorage.getItem("siteSelection") || "dk");
  const [activeTab, setActiveTab] = useState(0);
  const [selectedSlate, setSelectedSlate] = useState(localStorage.getItem("selectedSlate") || "");
  const [numLineups, setNumLineups] = useState(1);
  const [lineups, setLineups] = useState([]);
  const [previewLineup, setPreviewLineup] = useState({});
  const [news, setNews] = useState([]);
  const [bettingOdds, setBettingOdds] = useState([]);
  const [playerProps, setPlayerProps] = useState([]);
  const [stats, setStats] = useState([]);
  const [positionFilter, setPositionFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("playerName");
  const [sortDirection, setSortDirection] = useState("asc");
  const [exposure, setExposure] = useState(() => Number(localStorage.getItem("exposure")) || 35);
  const [playerExposures, setPlayerExposures] = useState(() => JSON.parse(localStorage.getItem("playerExposures")) || {});
  const [overriddenPlayers, setOverriddenPlayers] = useState(() => new Set(JSON.parse(localStorage.getItem("overriddenPlayers")) || []));
  const [lockedPlayers, setLockedPlayers] = useState(() => JSON.parse(localStorage.getItem("lockedPlayers")) || []);
  const [teamStacks, setTeamStacks] = useState(() => JSON.parse(localStorage.getItem("mlbTeamStacks")) || {});
  const [availableTeams, setAvailableTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [stackSize, setStackSize] = useState('');
  const [sortBy, setSortBy] = useState("projectedPoints");
  const [projectionType, setProjectionType] = useState("Projected");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/user`, { withCredentials: true })
      .then((res) => {
        if (!res.data.subscription) navigate("/dashboard");
      })
      .catch((err) => {
        console.error("❌ Error fetching subscription data:", err);
        navigate("/login");
      });
  }, [navigate]);

  useEffect(() => {
    const fetchSlates = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/mlb_slates`);
        const sortedSlates = res.data.sort((a, b) => b.slateID - a.slateID).slice(0, 5);
        setSlates(sortedSlates);
        if (selectedSlate && !sortedSlates.some(slate => slate.slateID === Number(selectedSlate))) {
          setSelectedSlate("");
          localStorage.removeItem("selectedSlate");
        }
      } catch (error) {
        console.error("❌ Slates API Error:", error);
        setSlates([]);
      }
    };
    fetchSlates();
  }, [siteSelection, selectedSlate]);

  useEffect(() => {
    const fetchProjections = async () => {
      if (selectedSlate) {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/mlb_projections`, {
            params: { slateID: selectedSlate, site: siteSelection },
          });
          setStats(res.data);
          const newPlayerExposures = { ...playerExposures };
          res.data.forEach(player => {
            if (!(player.playerID in newPlayerExposures)) {
              newPlayerExposures[player.playerID] = exposure;
            }
          });
          setPlayerExposures(newPlayerExposures);
          localStorage.setItem("playerExposures", JSON.stringify(newPlayerExposures));

          const teamsMap = new Map();
          res.data.forEach((p) => {
            if (p.teamID && p.teamName) {
              teamsMap.set(String(p.teamID), {
                id: String(p.teamID),
                name: p.teamName,
                logo: p.teamLogo || "",
              });
            }
          });
          setAvailableTeams(Array.from(teamsMap.values()).sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
          console.error("❌ Projections API Error:", error);
          setStats([]);
        }
      } else {
        setStats([]);
        setAvailableTeams([]);
      }
    };
    fetchProjections();
  }, [selectedSlate, siteSelection]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/mlb_news`);
        setNews(Array.from(new Map(res.data.map(item => [item.link, item])).values()));
      } catch (error) {
        console.error("News API Error:", error);
      }
    };

    const fetchBettingOdds = async () => {
      if (selectedSlate) {
        const slate = slates.find(s => s.slateID === Number(selectedSlate));
        if (slate) {
          try {
            const oddsRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/mlb_betting_odds`, {
              params: { date: slate.slateDate }
            });
            setBettingOdds(oddsRes.data);

            const propsRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/mlb_player_props`);
            setPlayerProps(propsRes.data);
          } catch (error) {
            console.error("Betting Odds API Error:", error);
          }
        }
      }
    };

    fetchNews();
    fetchBettingOdds();
  }, [selectedSlate, slates]);

  useEffect(() => {
    localStorage.setItem("selectedSlate", selectedSlate);
    localStorage.setItem("exposure", exposure);
    localStorage.setItem("playerExposures", JSON.stringify(playerExposures));
    localStorage.setItem("overriddenPlayers", JSON.stringify([...overriddenPlayers]));
    localStorage.setItem("lockedPlayers", JSON.stringify(lockedPlayers));
    localStorage.setItem("mlbTeamStacks", JSON.stringify(teamStacks));
  }, [selectedSlate, exposure, playerExposures, overriddenPlayers, lockedPlayers, teamStacks]);

  useEffect(() => {
    const newLineup = {};
    const lockedPlayerStats = stats.filter(p => lockedPlayers.includes(String(p.playerID)));
    lockedPlayerStats.forEach(player => {
      const position = getAvailablePosition(player, newLineup, siteSelection);
      if (position) {
        newLineup[position] = player;
      } else if (Object.keys(newLineup).length >= (siteSelection === 'dk' ? 10 : 9)) {
        alert(`No available position for ${player.playerName} - lineup is full`);
      }
    });
    setPreviewLineup(newLineup);
  }, [lockedPlayers, stats, siteSelection]);

  const handleSiteChange = (event) => {
    const newSite = event.target.value;
    if (lineups.length > 0 && !window.confirm("Switching will clear current lineups and settings. Continue?")) {
      return;
    }
    setSelectedSlate("");
    setLineups([]);
    setPreviewLineup({});
    setPlayerExposures({});
    setOverriddenPlayers(new Set());
    setLockedPlayers([]);
    setTeamStacks({});
    localStorage.removeItem("selectedSlate");
    localStorage.removeItem("playerExposures");
    localStorage.removeItem("overriddenPlayers");
    localStorage.removeItem("lockedPlayers");
    localStorage.removeItem("mlbTeamStacks");
    setSiteSelection(newSite);
    localStorage.setItem("siteSelection", newSite);
  };

  const handleExposureChange = (e, val) => {
    setExposure(val);
    const updatedExposures = {};
    stats.forEach(player => {
      updatedExposures[player.playerID] = overriddenPlayers.has(player.playerID) ? playerExposures[player.playerID] : val;
    });
    setPlayerExposures(updatedExposures);
  };

  const handlePlayerExposureChange = (playerId, value) => {
    const newValue = Math.max(0, Math.min(100, Number(value)));
    setPlayerExposures(prev => ({ ...prev, [playerId]: newValue }));
    setOverriddenPlayers(prev => new Set(prev).add(playerId));
  };

  const handleLockPlayer = (playerId) => {
    setLockedPlayers(prev => prev.includes(playerId) ? prev.filter(id => id !== playerId) : [...prev, playerId]);
  };

  const handleOptimize = () => {
    if (!selectedSlate) {
      alert("Please Select Slate First!");
      return;
    }

    const params = {
      slateID: Number(selectedSlate),
      numLineups: Number(numLineups),
      exposure_limit: Number(exposure) / 100,
      locked_players: lockedPlayers,
      projectionType: projectionType.toLowerCase(),
      player_exposures: playerExposures,
      team_stacks: teamStacks
    };

    const apiEndpoint = siteSelection === "dk" ? "run_mlb_optimizer_dk" : "run_mlb_optimizer_fd";
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/${apiEndpoint}`, params)
      .then((res) => {
        if (!res.data.lineups || res.data.lineups.length === 0) {
          alert("No lineups returned from optimizer!");
          return;
        }
        if (numLineups === 1) {
          setPreviewLineup(res.data.lineups[0] || {});
          setActiveTab(0);
        } else {
          setLineups(res.data.lineups || []);
          setActiveTab(1);
        }
      })
      .catch((error) => {
        console.error("Optimization Error:", error);
        alert(`Optimization failed: ${error.response?.data?.error || error.message}`);
      });
  };

  const exportLineupsToCSV = () => {
    if (lineups.length === 0) {
      alert("No lineups to export!");
      return;
    }

    const headers = siteSelection === "dk" 
      ? ["P", "P", "C", "1B", "2B", "3B", "SS", "OF", "OF", "OF"] 
      : ["P", "C", "1B", "2B", "3B", "SS", "OF", "OF", "UTIL"];
    const csvRows = [headers.join(",")];

    lineups.forEach((lineup) => {
      const lineupPositions = {};
      Object.keys(lineup).forEach((pos) => {
        if (typeof lineup[pos] === "object" && (lineup[pos].dkID || lineup[pos].fdID)) {
          const positionKey = pos.split("_")[0];
          const id = siteSelection === "dk" ? lineup[pos].dkID : lineup[pos].fdID;
          if (positionKey === "P" && !lineupPositions["P"]) lineupPositions["P"] = id;
          else if (positionKey === "P") lineupPositions["P_2"] = id;
          else if (positionKey === "OF" && !lineupPositions["OF"]) lineupPositions["OF"] = id;
          else if (positionKey === "OF" && !lineupPositions["OF_2"]) lineupPositions["OF_2"] = id;
          else if (positionKey === "OF") lineupPositions["OF_3"] = id;
          else lineupPositions[positionKey] = id;
        }
      });

      const row = headers.map((header) => lineupPositions[header] || "");
      csvRows.push(row.join(","));
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `mlb_lineups_${selectedSlate}_${siteSelection}.csv`;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const filteredStats = stats
    .filter((player) => {
      const matchesSearch = player.playerName && player.playerName.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesPosition = positionFilter === "" || 
        (player.position && (
          player.position.includes(positionFilter) || 
          (positionFilter === "OF" && ["LF", "RF", "CF"].some(pos => player.position.includes(pos)))
        ));
      return matchesSearch && matchesPosition;
    })
    .sort((a, b) => {
      let valueA = a[sortColumn];
      let valueB = b[sortColumn];
      if (sortColumn === "salary" || sortColumn === "projectedPTS" || sortColumn === "ceilingPTS") {
        valueA = Number(valueA) || 0;
        valueB = Number(valueB) || 0;
        return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
      }
      valueA = valueA || "";
      valueB = valueB || "";
      if (typeof valueA === "string") valueA = valueA.toLowerCase();
      if (typeof valueB === "string") valueB = valueB.toLowerCase();
      return sortDirection === "asc" ? (valueA > valueB ? 1 : -1) : (valueA < valueB ? 1 : -1);
    });

  const calculatePlayerExposures = () => {
    const exposureCount = {};
    lineups.forEach(lineup => {
      Object.values(lineup).forEach(player => {
        if (typeof player === 'object' && player.playerID) {
          exposureCount[player.playerID] = (exposureCount[player.playerID] || 0) + 1;
        }
      });
    });
    return Object.entries(exposureCount).map(([playerID, count]) => {
      const player = stats.find(p => String(p.playerID) === String(playerID));
      return {
        playerName: player?.playerName || 'Unknown',
        exposure: ((count / lineups.length) * 100).toFixed(1)
      };
    });
  };

  const calculateTeamExposures = () => {
    const exposureCount = {};
    lineups.forEach(lineup => {
      const teamsInLineup = new Set();
      Object.values(lineup).forEach(player => {
        if (typeof player === 'object' && player.teamName) {
          teamsInLineup.add(player.teamName);
        }
      });
      teamsInLineup.forEach(teamName => {
        exposureCount[teamName] = (exposureCount[teamName] || 0) + 1;
      });
    });
    return Object.entries(exposureCount).map(([teamName, count]) => ({
      teamName: teamName,
      exposure: ((count / lineups.length) * 100).toFixed(1)
    }));
  };

  const sortedLineups = [...lineups].sort((a, b) => {
    if (sortBy === "projectedPoints") {
      return (b.ProjectedDKPoints || 0) - (a.ProjectedDKPoints || 0);
    } else if (sortBy === "ceilingPoints") {
      return (b.DKCeilingPoints || 0) - (a.DKCeilingPoints || 0);
    } else if (sortBy === "totalSalary") {
      const aSalary = parseInt(a.TotalSalary.replace(/[$,]/g, ""));
      const bSalary = parseInt(b.TotalSalary.replace(/[$,]/g, ""));
      return bSalary - aSalary;
    }
    return 0;
  });

  const calculatePreviewTotals = () => {
    const positions = siteSelection === 'dk' ? 10 : 9;
    const maxSalary = siteSelection === 'dk' ? 50000 : 60000;
    
    const totalSalary = Object.values(previewLineup)
      .reduce((sum, player) => sum + (player?.salary || 0), 0);
    const totalProjected = Object.values(previewLineup)
      .reduce((sum, player) => sum + (player?.projectedPTS || 0), 0);
    const totalCeiling = Object.values(previewLineup)
      .reduce((sum, player) => sum + (player?.ceilingPTS || 0), 0);
    const filledSpots = Object.keys(previewLineup).length;
    
    return {
      salary: totalSalary,
      remainingSalary: maxSalary - totalSalary,
      perPlayerRemaining: filledSpots < positions ? (maxSalary - totalSalary) / (positions - filledSpots) : 0,
      projected: totalProjected,
      ceiling: totalCeiling
    };
  };

  const formatTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffMs = now - date;
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    if (diffHours < 1) return "Less than an hour ago";
    if (diffHours < 24) return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    return `${Math.floor(diffHours / 24)} day${diffHours >= 48 ? 's' : ''} ago`;
  };

  const clearLockedPlayers = () => {
    setLockedPlayers([]);
    setPreviewLineup({});
  };

  const clearPlayerExposures = () => {
    setPlayerExposures({});
    setOverriddenPlayers(new Set());
  };

  const clearStacking = () => {
    setTeamStacks({});
  };
const renderPlayerSymbols = (player) => {
  const { questionable, isConfirmed, battingOrder, position } = player;
  const isPitcher = position && position.includes('P');
  const symbols = [];

  // Questionable/Probable Symbol
  if (questionable === 'Q' || questionable === 'P') {
    symbols.push(
      <Typography
        key="questionable"
        component="span"
        sx={{ color: '#ff0000', fontWeight: 'bold', ml: 1 }}
      >
        {questionable}
      </Typography>
    );
  }

  // Confirmation and Batting Order Symbol
  const symbolStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24, // Slightly larger for better visibility
    height: 24,
    bgcolor: isConfirmed === 1 ? '#00e676' : '#808080', // Green for confirmed, gray for unconfirmed
    color: '#fff', // White text for contrast
    borderRadius: 0, // Square shape
    ml: 1,
    fontSize: 14, // Larger font for clarity
    fontWeight: 'bold',
  };

  symbols.push(
    <Box key="confirmation" sx={symbolStyle}>
      {!isPitcher && battingOrder ? battingOrder : ''}
    </Box>
  );

  return symbols;
};
  return (
    <Container sx={{ bgcolor: '#1a1a1a', minHeight: '100vh', py: 4, color: '#fff' }}>
      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)} 
        variant="fullWidth" 
        sx={{ mb: 3, bgcolor: '#2c2c2c', borderRadius: 2, '& .MuiTab-root': { color: '#fff', fontWeight: 'bold' } }}
      >
        <Tab label="Optimizer" />
        <Tab label="Results" />
        <Tab label="Stacks" />
        <Tab label="News" />
        <Tab label="Betting Odds" />
      </Tabs>

      <Grid container spacing={3}>
        {activeTab === 0 && (
          <Grid item xs={12}>
            <Box sx={{ display: "flex", gap: 2, mb: 4, flexWrap: "wrap", bgcolor: '#2c2c2c', p: 2, borderRadius: 2 }}>
              <TextField 
                select 
                label="Select Site" 
                value={siteSelection} 
                onChange={handleSiteChange} 
                sx={{ minWidth: 150, '& .MuiInputBase-root': { bgcolor: '#3d3d3d', color: '#fff' } }}
                InputLabelProps={{ style: { color: '#fff' }, shrink: true }}
              >
                <MenuItem value="dk">DraftKings</MenuItem>
                <MenuItem value="fd">FanDuel</MenuItem>
              </TextField>
              <TextField 
                select 
                label="Select Slate" 
                value={selectedSlate} 
                onChange={(e) => setSelectedSlate(e.target.value)} 
                sx={{ minWidth: 200, '& .MuiInputBase-root': { bgcolor: '#3d3d3d', color: '#fff' } }}
                InputLabelProps={{ style: { color: '#fff' }, shrink: true }}
              >
                {slates.map((slate) => (
                  <MenuItem key={slate.slateID} value={slate.slateID}>{slate.slateName}</MenuItem>
                ))}
              </TextField>
              <TextField 
                select 
                label="Projection Type" 
                value={projectionType} 
                onChange={(e) => setProjectionType(e.target.value)} 
                sx={{ minWidth: 150, '& .MuiInputBase-root': { bgcolor: '#3d3d3d', color: '#fff' } }}
                InputLabelProps={{ style: { color: '#fff' }, shrink: true }}
              >
                <MenuItem value="Projected">Projected</MenuItem>
                <MenuItem value="Ceiling">Ceiling</MenuItem>
              </TextField>
              <TextField 
                type="number" 
                label="Number of Lineups" 
                value={numLineups} 
                onChange={(e) => setNumLineups(e.target.value)} 
                sx={{ minWidth: 150, '& .MuiInputBase-root': { bgcolor: '#3d3d3d', color: '#fff' } }} 
                InputLabelProps={{ style: { color: '#fff' }, shrink: true }}
              />
              <Button 
                variant="contained" 
                onClick={handleOptimize} 
                sx={{ bgcolor: '#00e676', color: '#fff', fontWeight: 'bold' }}
              >
                Optimize
              </Button>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography gutterBottom sx={{ color: '#00e676', fontWeight: 'bold' }}>
                Global Exposure Limit (%)
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Slider 
                  value={exposure} 
                  onChange={handleExposureChange} 
                  min={0} 
                  max={100} 
                  valueLabelDisplay="auto" 
                  sx={{ maxWidth: 300, color: '#00e676' }} 
                />
                <TextField
                  type="number"
                  value={exposure}
                  onChange={(e) => {
                    const newValue = Math.max(0, Math.min(100, Number(e.target.value)));
                    setExposure(newValue);
                    handleExposureChange(null, newValue);
                  }}
                  inputProps={{ min: 0, max: 100, step: 1 }}
                  size="small"
                  sx={{ width: 80, '& .MuiInputBase-root': { bgcolor: '#3d3d3d', color: '#fff' } }}
                  InputLabelProps={{ style: { color: '#fff' }, shrink: true }}
                />
              </Box>
            </Box>

            <Box sx={{ mb: 4, display: 'flex', gap: 2 }}>
              <Button variant="outlined" onClick={clearLockedPlayers} sx={{ borderColor: '#00e676', color: '#00e676' }}>
                Clear Locked Players
              </Button>
              <Button variant="outlined" onClick={clearPlayerExposures} sx={{ borderColor: '#00e676', color: '#00e676' }}>
                Clear Exposure Overrides
              </Button>
            </Box>

            <Card sx={{ height: isMobile ? "70vh" : "500px", bgcolor: "#2c2c2c", borderRadius: 2 }}>
              <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <Typography variant="h6" sx={{ textAlign: "center", mb: 2, color: "#00e676", fontWeight: "bold" }}>
                  Player Stats
                </Typography>
                <Box sx={{ display: "flex", gap: 2, mb: 2, flexWrap: "wrap", alignItems: "center" }}>
                  <TextField
                    label="Search Player"
                    fullWidth={isMobile}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ flex: 1, minWidth: 200, "& .MuiInputBase-root": { bgcolor: "#3d3d3d", color: "#fff" } }}
                  />
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {["P", "C", "1B", "2B", "3B", "SS", "OF"].map((pos) => (
                      <Chip
                        key={pos}
                        label={pos}
                        onClick={() => setPositionFilter(positionFilter === pos ? "" : pos)}
                        color={positionFilter === pos ? "primary" : "default"}
                        clickable
                        sx={{ bgcolor: positionFilter === pos ? "#00e676" : "#3d3d3d", color: "#fff" }}
                      />
                    ))}
                  </Box>
                </Box>
                <Box sx={{ flex: 1, overflowY: "auto" }}>
                  <Table sx={{ minWidth: isMobile ? "1200px" : "100%", bgcolor: "#2c2c2c" }}>
                    <TableHead sx={{ bgcolor: "#3d3d3d", position: "sticky", top: 0, zIndex: 1 }}>
                      <TableRow>
                        <TableCell>
                          <Button
                            onClick={() => {
                              setSortColumn("playerName");
                              setSortDirection(sortColumn === "playerName" && sortDirection === "asc" ? "desc" : "asc");
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Player {sortColumn === "playerName" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              setSortColumn("position");
                              setSortDirection(sortColumn === "position" && sortDirection === "asc" ? "desc" : "asc");
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Position {sortColumn === "position" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              setSortColumn("teamName");
                              setSortDirection(sortColumn === "teamName" && sortDirection === "asc" ? "desc" : "asc");
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Team {sortColumn === "teamName" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              setSortColumn("opponentTeam");
                              setSortDirection(sortColumn === "opponentTeam" && sortDirection === "asc" ? "desc" : "asc");
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Opponent {sortColumn === "opponentTeam" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              setSortColumn("salary");
                              setSortDirection(sortColumn === "salary" && sortDirection === "asc" ? "desc" : "asc");
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Salary {sortColumn === "salary" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              setSortColumn("projectedPTS");
                              setSortDirection(sortColumn === "projectedPTS" && sortDirection === "asc" ? "desc" : "asc");
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Projected {sortColumn === "projectedPTS" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              setSortColumn("ceilingPTS");
                              setSortDirection(sortColumn === "ceilingPTS" && sortDirection === "asc" ? "desc" : "asc");
                            }}
                            sx={{ color: "#fff", textTransform: "none", p: 0 }}
                          >
                            Ceiling {sortColumn === "ceilingPTS" && (sortDirection === "asc" ? "↑" : "↓")}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }}>Exposure (%)</TableCell>
                      </TableRow>
                    </TableHead>
                   <TableBody>
					  {filteredStats.map((player) => (
						<TableRow key={player.playerID} sx={{ "&:hover": { bgcolor: "#3d3d3d" } }}>
						  <TableCell sx={{ color: "#fff" }}>
							<IconButton
							  onClick={() => handleLockPlayer(String(player.playerID))}
							  sx={{ color: lockedPlayers.includes(String(player.playerID)) ? "#00e676" : "#fff" }}
							>
							  {lockedPlayers.includes(String(player.playerID)) ? <LockIcon /> : <LockOpenIcon />}
							</IconButton>
							{player.playerName}
							{renderPlayerSymbols(player)}
						  </TableCell>
						  <TableCell sx={{ color: "#fff" }}>{player.position}</TableCell>
						  <TableCell sx={{ color: "#fff" }}>{player.teamName}</TableCell>
						  <TableCell sx={{ color: "#fff" }}>{player.opponentTeam || "N/A"}</TableCell>
						  <TableCell sx={{ color: "#fff" }}>${player.salary}</TableCell>
						  <TableCell sx={{ color: "#fff" }}>{player.projectedPTS}</TableCell>
						  <TableCell sx={{ color: "#fff" }}>{player.ceilingPTS}</TableCell>
						  <TableCell>
							<TextField
							  type="number"
							  value={playerExposures[player.playerID] ?? exposure}
							  onChange={(e) => handlePlayerExposureChange(player.playerID, e.target.value)}
							  inputProps={{ min: 0, max: 100, step: 1, style: { width: 60, color: "#fff" } }}
							  size="small"
							  sx={{ "& .MuiInputBase-root": { bgcolor: "#3d3d3d" } }}
							/>
						  </TableCell>
						</TableRow>
					  ))}
					</TableBody>
                  </Table>
                </Box>
              </CardContent>
            </Card>

            <Card sx={{ mt: 4, bgcolor: '#2c2c2c', borderRadius: 2 }}>
              <CardContent sx={{ height: isMobile ? "50vh" : "400px", display: "flex", flexDirection: "column" }}>
                <Typography variant="h6" sx={{ mb: 2, color: '#00e676', fontWeight: 'bold' }}>
                  Lineup Preview
                </Typography>
                <Box sx={{ flex: 1, overflowY: "auto" }}>
                  <Table sx={{ minWidth: isMobile ? "700px" : "100%", bgcolor: '#2c2c2c' }}>
                    <TableHead sx={{ bgcolor: '#3d3d3d', position: "sticky", top: 0, zIndex: 1 }}>
                      <TableRow>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Position</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Player</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Pos</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Team</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Opp</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Salary</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Proj</TableCell>
                        <TableCell sx={{ color: '#fff', py: 1 }}>Ceil</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(siteSelection === 'dk' 
						? ['P_1', 'P_2', 'C', '1B', '2B', '3B', 'SS', 'OF_1', 'OF_2', 'OF_3']
						: ['P', 'C', '1B', '2B', '3B', 'SS', 'OF_1', 'OF_2', 'UTIL']
					  ).map((pos) => {
						const player = previewLineup[pos];
						return (
						  <TableRow key={pos} sx={{ '&:hover': { bgcolor: '#3d3d3d' } }}>
							<TableCell sx={{ color: '#fff', py: 0.5 }}>{pos.replace('_1', '').replace('_2', '').replace('_3', '')}</TableCell>
							<TableCell sx={{ color: '#fff', py: 0.5 }}>
							  {player ? (
								<>
								  {player.playerName}
								  {renderPlayerSymbols(player)}
								</>
							  ) : '-'}
							</TableCell>
							<TableCell sx={{ color: '#fff', py: 0.5 }}>{player ? player.position : '-'}</TableCell>
							<TableCell sx={{ color: '#fff', py: 0.5 }}>{player ? player.teamName : '-'}</TableCell>
							<TableCell sx={{ color: '#fff', py: 0.5 }}>{player ? player.opponentTeam || 'N/A' : '-'}</TableCell>
							<TableCell sx={{ color: '#fff', py: 0.5 }}>
							  {player?.salary ? `$${player.salary.toLocaleString()}` : '-'}
							</TableCell>
							<TableCell sx={{ color: '#fff', py: 0.5 }}>
							  {player?.projectedPTS != null ? Number(player.projectedPTS).toFixed(2) : '-'}
							</TableCell>
							<TableCell sx={{ color: '#fff', py: 0.5 }}>
							  {player?.ceilingPTS != null ? player.ceilingPTS.toFixed(2) : '-'}
							</TableCell>
						  </TableRow>
						);
					  })}
                      {(() => {
                        const totals = calculatePreviewTotals();
                        return (
                          <TableRow sx={{ bgcolor: '#3d3d3d', fontWeight: 'bold' }}>
                            <TableCell colSpan={5} sx={{ color: '#fff', py: 0.5 }}><strong>Total</strong></TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>
                              <strong>${totals.salary.toLocaleString()}</strong><br/>
                              <small>Rem: ${totals.remainingSalary.toLocaleString()}</small><br/>
                              <small>Per: ${Number(totals.perPlayerRemaining).toFixed(0)}</small>
                            </TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>
                              <strong>{totals.projected.toFixed(2)}</strong>
                            </TableCell>
                            <TableCell sx={{ color: '#fff', py: 0.5 }}>
                              <strong>{totals.ceiling.toFixed(2)}</strong>
                            </TableCell>
                          </TableRow>
                        );
                      })()}
                    </TableBody>
                  </Table>
                </Box>
              </CardContent>
            </Card>
			<Box sx={{ mt: 3, textAlign: "center" }}>
              <Button 
                variant="text" 
                onClick={() => navigate("/dashboard")} 
                sx={{ 
                  color: '#00e676', 
                  fontWeight: 'bold', 
                  transition: 'all 0.3s ease', 
                  '&:hover': { color: '#00c853' } 
                }}
              >
                Return to Dashboard
              </Button>
            </Box>
          </Grid>
        )}

        {activeTab === 1 && (
          <Grid item xs={12}>
            <Card sx={{ height: isMobile ? "auto" : "645px", bgcolor: '#2c2c2c', borderRadius: 2 }}>
              <CardContent sx={{ height: "100%", overflowY: "auto" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                  <Typography variant="h6" sx={{ color: '#00e676', fontWeight: 'bold' }}>
                    Lineup Results
                  </Typography>
                  <Button variant="outlined" onClick={exportLineupsToCSV} sx={{ borderColor: '#00e676', color: '#00e676' }}>
                    Export Lineups
                  </Button>
                </Box>
                {lineups.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <TextField 
                      select 
                      label="Sort By" 
                      value={sortBy} 
                      onChange={(e) => setSortBy(e.target.value)} 
                      size="small" 
                      sx={{ minWidth: 150, '& .MuiInputBase-root': { bgcolor: '#3d3d3d', color: '#fff' } }}
                      InputLabelProps={{ style: { color: '#fff' }, shrink: true }}
                    >
                      <MenuItem value="projectedPoints">Projected Points</MenuItem>
                      <MenuItem value="ceilingPoints">Ceiling Points</MenuItem>
                      <MenuItem value="totalSalary">Total Salary</MenuItem>
                    </TextField>
                  </Box>
                )}
                <div style={{ overflowX: "auto" }}>
                  {sortedLineups.map((lineup, index) => {
                    let totalSalary = 0;
                    let totalProjected = 0;
                    let totalCeiling = 0;

                    const positionOrder = siteSelection === "dk" 
                      ? ["P_1", "P_2", "C", "1B", "2B", "3B", "SS", "OF_1", "OF_2", "OF_3"]
                      : ["P", "C", "1B", "2B", "3B", "SS", "OF_1", "OF_2", "UTIL"];

                    return (
                      <Box key={index} sx={{ mb: 4 }}>
                        <Typography variant="h6" sx={{ mb: 1, color: '#00e676', fontWeight: 'bold' }}>
                          Lineup {index + 1}
                        </Typography>
                        <Table sx={{ minWidth: isMobile ? "800px" : "100%", bgcolor: '#2c2c2c' }}>
                          <TableHead sx={{ bgcolor: '#3d3d3d' }}>
                            <TableRow>
                              <TableCell sx={{ color: '#fff' }}>Position</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Player</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Position</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Team</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Opponent</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Salary</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Projected</TableCell>
                              <TableCell sx={{ color: '#fff' }}>Ceiling</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {positionOrder.map((pos) => {
							  const player = lineup[pos];
							  if (player) {
								totalSalary += player.salary || 0;
								totalProjected += player.projectedPTS || 0;
								totalCeiling += player.ceilingPTS || 0;
								return (
								  <TableRow key={pos} sx={{ '&:hover': { bgcolor: '#3d3d3d' } }}>
									<TableCell sx={{ color: '#fff' }}>{pos.replace('_1', '').replace('_2', '').replace('_3', '')}</TableCell>
									<TableCell sx={{ color: '#fff' }}>
									  {player.playerName}
									  {renderPlayerSymbols(player)}
									</TableCell>
									<TableCell sx={{ color: '#fff' }}>{player.position}</TableCell>
									<TableCell sx={{ color: '#fff' }}>{player.teamName}</TableCell>
									<TableCell sx={{ color: '#fff' }}>{player.opponentTeam || 'N/A'}</TableCell>
									<TableCell sx={{ color: '#fff' }}>${player.salary?.toLocaleString()}</TableCell>
									<TableCell sx={{ color: '#fff' }}>{player.projectedPTS?.toFixed(2)}</TableCell>
									<TableCell sx={{ color: '#fff' }}>{player.ceilingPTS?.toFixed(2)}</TableCell>
								  </TableRow>
								);
							  }
							  return null;
							})}
                            <TableRow sx={{ bgcolor: '#3d3d3d', fontWeight: 'bold' }}>
                              <TableCell colSpan={5} sx={{ color: '#fff' }}><strong>Total</strong></TableCell>
                              <TableCell sx={{ color: '#fff' }}><strong>${totalSalary.toLocaleString()}</strong></TableCell>
                              <TableCell sx={{ color: '#fff' }}><strong>{totalProjected.toFixed(2)}</strong></TableCell>
                              <TableCell sx={{ color: '#fff' }}><strong>{totalCeiling.toFixed(2)}</strong></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    );
                  })}
                  {sortedLineups.length > 0 && (
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4, mb: 2 }}>
                        <Box sx={{ width: '48%' }}>
                          <Typography variant="h6" sx={{ mb: 2, color: '#00e676', fontWeight: 'bold' }}>
                            Player Exposure Summary
                          </Typography>
                          <Table sx={{ maxWidth: "400px", bgcolor: '#2c2c2c' }}>
                            <TableHead sx={{ bgcolor: '#3d3d3d' }}>
                              <TableRow>
                                <TableCell sx={{ color: '#fff' }}>Player</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Exposure (%)</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {calculatePlayerExposures().map((exp, index) => (
                                <TableRow key={index} sx={{ '&:hover': { bgcolor: '#3d3d3d' } }}>
                                  <TableCell sx={{ color: '#fff' }}>{exp.playerName}</TableCell>
                                  <TableCell sx={{ color: '#fff' }}>{exp.exposure}%</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                        <Box sx={{ width: '48%' }}>
                          <Typography variant="h6" sx={{ mb: 2, color: '#00e676', fontWeight: 'bold' }}>
                            Team Exposure Summary
                          </Typography>
                          <Table sx={{ maxWidth: "400px", bgcolor: '#2c2c2c' }}>
                            <TableHead sx={{ bgcolor: '#3d3d3d' }}>
                              <TableRow>
                                <TableCell sx={{ color: '#fff' }}>Team</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Exposure (%)</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {calculateTeamExposures().map((exp, index) => (
                                <TableRow key={index} sx={{ '&:hover': { bgcolor: '#3d3d3d' } }}>
                                  <TableCell sx={{ color: '#fff' }}>{exp.teamName}</TableCell>
                                  <TableCell sx={{ color: '#fff' }}>{exp.exposure}%</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Box>
                      <Typography variant="body1" sx={{ mt: 2, textAlign: "center", color: '#00e676' }}>
                        Total Lineups: {sortedLineups.length}
                      </Typography>
                    </>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}

        {activeTab === 2 && (
		  <Grid item xs={12}>
			<Card sx={{ bgcolor: '#2c2c2c', borderRadius: 2 }}>
			  <CardContent>
				<Typography variant="h6" sx={{ color: '#00e676', fontWeight: 'bold', mb: 2 }}>
				  Team Stacking
				</Typography>
				<Box sx={{ mb: 4 }}>
				  <Grid container spacing={2}>
					<Grid item xs={8}>
					  <Select 
						fullWidth 
						value={selectedTeam} 
						onChange={(e) => setSelectedTeam(e.target.value)} 
						displayEmpty
						sx={{ bgcolor: '#3d3d3d', color: '#fff', borderRadius: 1 }}
					  >
						<MenuItem value="" disabled>Select Team</MenuItem>
						{availableTeams.map((team) => (
						  <MenuItem key={team.id} value={team.id}>
							<Box display="flex" alignItems="center">
							  {team.logo && <img src={team.logo} alt={team.name} style={{ height: 20, marginRight: 8 }} />}
							  {team.name || "Unknown Team"}
							</Box>
						  </MenuItem>
						))}
					  </Select>
					</Grid>
					<Grid item xs={2}>
					  <TextField 
						type="number"
						label="Exact Players"
						value={stackSize}
						onChange={(e) => {
						  const value = e.target.value;
						  // Allow empty string or valid numbers between 2 and 5
						  if (value === '' || (/^\d+$/.test(value) && Number(value) >= 2 && Number(value) <= 5)) {
							setStackSize(value);
						  }
						}}
						placeholder="2-5"
						inputProps={{ 
						  min: 2, 
						  max: 5,
						  style: { color: '#fff' }
						}}
						sx={{ 
						  '& .MuiInputBase-root': { 
							bgcolor: '#3d3d3d', 
							color: '#fff', 
							borderRadius: 1,
							minWidth: isMobile ? '100px' : '120px' // Wider input: 100px on mobile, 120px on desktop
						  },
						  '& .MuiInputLabel-root': { color: '#fff' }
						}}
						InputLabelProps={{ shrink: true }}
					  />
					</Grid>
					<Grid item xs={2}>
					  <Button 
						variant="contained" 
						onClick={() => { 
						  if (stackSize === '' || Number(stackSize) < 2) {
							alert('Please enter a valid number of players (2-5)');
							return;
						  }
						  setTeamStacks({ ...teamStacks, [selectedTeam]: Number(stackSize) }); 
						  setSelectedTeam(""); 
						  setStackSize(''); // Reset to blank after adding
						}} 
						disabled={!selectedTeam || stackSize === ''}
						sx={{ bgcolor: '#00e676', color: '#fff', borderRadius: 2 }}
					  >
						Add
					  </Button>
					</Grid>
				  </Grid>
				  <Box sx={{ mt: 2 }}>
					{Object.entries(teamStacks).map(([teamId, size]) => {
					  const team = availableTeams.find((t) => t.id === teamId);
					  return (
						<Chip 
						  key={teamId} 
						  label={
							<Box display="flex" alignItems="center">
							  {team?.logo && <img src={team.logo} alt={team.name} style={{ height: 20, marginRight: 4 }} />}
							  {team ? team.name : teamId} ({size})
							</Box>
						  } 
						  onDelete={() => {
							const newStacks = { ...teamStacks };
							delete newStacks[teamId];
							setTeamStacks(newStacks);
						  }} 
						  sx={{ m: 0.5, bgcolor: '#3d3d3d', color: '#fff' }} 
						/>
					  );
					})}
				  </Box>
				</Box>
				<Box sx={{ mt: 2 }}>
				  <Button variant="outlined" onClick={clearStacking} sx={{ borderColor: '#00e676', color: '#00e676' }}>
					Clear Stacking Settings
				  </Button>
				</Box>
			  </CardContent>
			</Card>
		  </Grid>
		)}

        {activeTab === 3 && (
          <Grid item xs={12}>
            <Card sx={{ maxHeight: isMobile ? "auto" : "auto", bgcolor: '#2c2c2c', borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6" sx={{ color: '#00e676', fontWeight: 'bold', mb: 2 }}>
                  MLB News
                </Typography>
                <div style={{ flex: 1, overflowY: "auto", paddingRight: "10px" }}>
                  {news.length > 0 ? news.map((item, index) => (
                    <Typography key={index} sx={{ mb: 2, '&:hover': { color: '#00e676' } }}>
                      <a href={item.link} target="_blank" rel="noopener noreferrer" style={{ color: "#fff", textDecoration: "none" }}>
                        {item.title.length > 60 ? `${item.title.substring(0, 60)}...` : item.title}
                      </a>
                      <Typography variant="caption" display="block" sx={{ color: '#b0b0b0' }}>
                        {formatTimeAgo(item.createdAt)}
                      </Typography>
                    </Typography>
                  )) : (
                    <Typography sx={{ color: '#fff' }}>No news available at this time.</Typography>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}

        {activeTab === 4 && (
          <Grid item xs={12}>
            <Card sx={{ maxHeight: isMobile ? "auto" : "auto", bgcolor: '#2c2c2c', borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6" sx={{ color: '#00e676', fontWeight: 'bold', mb: 2 }}>
                  MLB Betting Odds
                </Typography>
                <div style={{ flex: 1, overflowY: "auto", paddingRight: "10px" }}>
                  {bettingOdds.length > 0 ? bettingOdds.map((game, index) => {
                    const calculateImpliedTotals = (runLine, total) => {
                      if (!runLine || !total) return { home: 'N/A', away: 'N/A' };
                      const adjustedTotal = total / 2;
                      const homeImplied = adjustedTotal - (runLine / 2);
                      const awayImplied = adjustedTotal + (runLine / 2);
                      return { home: homeImplied.toFixed(1), away: awayImplied.toFixed(1) };
                    };

                    const sportsbooks = [
                      { name: 'BetMGM', prefix: 'betmgm' },
                      { name: 'Bet365', prefix: 'bet365' },
                      { name: 'FanDuel', prefix: 'fanduel' },
                      { name: 'ESPN BET', prefix: 'espnbet' },
                      { name: 'Caesars', prefix: 'caesars' },
                      { name: 'DraftKings', prefix: 'draftkings' }
                    ];

                    return (
                      <Box key={index} sx={{ mb: 3, borderBottom: '1px solid #3d3d3d', pb: 2 }}>
                        <Typography sx={{ color: '#fff', fontWeight: 'bold', mb: 1 }}>
                          <img src={game.homeTeamLogo} alt={game.homeTeam} width="20" height="20" style={{ marginRight: 4 }} /> 
                          {game.homeTeam} vs.
                          <img src={game.awayTeamLogo} alt={game.awayTeam} width="20" height="20" style={{ marginLeft: 4, marginRight: 4 }} /> 
                          {game.awayTeam}
                        </Typography>
                        {sportsbooks.map((book) => {
                          const prefix = book.prefix;
                          const hasData = game[`${prefix}_awayRunLine`] || game[`${prefix}_homeMLOdds`];
                          if (!hasData) return null;

                          const impliedTotals = calculateImpliedTotals(
                            game[`${prefix}_homeRunLine`],
                            game[`${prefix}_totalOver`]
                          );

                          return (
                            <Box key={book.name} sx={{ border: '1px solid #3d3d3d', borderRadius: 1, p: 1, mb: 1, bgcolor: '#303030' }}>
                              <Typography variant="subtitle2" sx={{ color: '#00e676', fontWeight: 'bold' }}>
                                {book.name}
                              </Typography>
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                <Typography variant="body2" sx={{ color: '#fff' }}>
                                  <strong>Run Line:</strong> {game[`${prefix}_awayRunLine`] || 'N/A'} ({game[`${prefix}_awayRunLineOdds`] || 'N/A'}) Away / 
                                  {game[`${prefix}_homeRunLine`] || 'N/A'} ({game[`${prefix}_homeRunLineOdds`] || 'N/A'}) Home
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#fff' }}>
                                  <strong>Moneyline:</strong> {game[`${prefix}_awayMLOdds`] || 'N/A'} (Away) / 
                                  {game[`${prefix}_homeMLOdds`] || 'N/A'} (Home)
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#fff' }}>
                                  <strong>Total:</strong> O {game[`${prefix}_totalOver`] || 'N/A'} ({game[`${prefix}_totalOverOdds`] || 'N/A'}) / 
                                  U {game[`${prefix}_totalUnder`] || 'N/A'} ({game[`${prefix}_totalUnderOdds`] || 'N/A'})
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#fff' }}>
                                  <strong>Implied Totals:</strong> {impliedTotals.away} (Away) / {impliedTotals.home} (Home)
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    );
                  }) : (
                    <Typography sx={{ color: '#fff' }}>No betting odds available for the selected slate.</Typography>
                  )}
                  {playerProps.length > 0 && (
				  <>
					<Typography variant="h6" sx={{ mt: 4, mb: 2, color: '#00e676', fontWeight: 'bold' }}>
					  Player Props
					</Typography>
					<Table sx={{ minWidth: isMobile ? "800px" : "100%", bgcolor: '#2c2c2c' }}>
					  <TableHead sx={{ bgcolor: '#3d3d3d' }}>
						<TableRow>
						  <TableCell sx={{ color: '#fff' }}>Player</TableCell> {/* Changed from "Player ID" */}
						  <TableCell sx={{ color: '#fff' }}>Strikeouts</TableCell>
						  <TableCell sx={{ color: '#fff' }}>Earned Runs</TableCell>
						  <TableCell sx={{ color: '#fff' }}>Bases</TableCell>
						  <TableCell sx={{ color: '#fff' }}>Runs</TableCell>
						</TableRow>
					  </TableHead>
					  <TableBody>
						{playerProps.map((prop, index) => (
						  <TableRow key={index} sx={{ '&:hover': { bgcolor: '#3d3d3d' } }}>
							<TableCell sx={{ color: '#fff' }}>{prop.playerName || prop.playerID}</TableCell> {/* Use playerName */}
							<TableCell sx={{ color: '#fff' }}>
							  {prop.strikeouts_total} (O: {prop.strikeouts_over || 'N/A'}, U: {prop.strikeouts_under || 'N/A'})
							</TableCell>
							<TableCell sx={{ color: '#fff' }}>
							  {prop.er_total} (O: {prop.er_over || 'N/A'}, U: {prop.er_under || 'N/A'})
							</TableCell>
							<TableCell sx={{ color: '#fff' }}>
							  {prop.bases_total} (O: {prop.bases_over || 'N/A'}, U: {prop.bases_under || 'N/A'})
							</TableCell>
							<TableCell sx={{ color: '#fff' }}>
							  {prop.runs_total} (O: {prop.runs_over || 'N/A'}, U: {prop.runs_under || 'N/A'})
							</TableCell>
						  </TableRow>
						))}
					  </TableBody>
					</Table>
				  </>
				)}
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default MLBOptimizerUI;