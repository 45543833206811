import React from "react";
import { Container, Typography, Box, Button, Paper } from "@mui/material";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import { useNavigate } from "react-router-dom";

const OptimizerUInfl = () => {
	const navigate = useNavigate();
  return (
    <Container maxWidth="md" sx={{ mt: 8, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4, textAlign: "center" }}>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <SportsFootballIcon sx={{ fontSize: 60, color: "#1976d2" }} />
        </Box>
        <Typography variant="h4" component="h1" gutterBottom color="primary">
          NFL Optimizer & Stats
        </Typography>
        <Typography variant="h6" color="textSecondary" paragraph>
          Coming Soon!
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph>
          We’re excited to bring you our NFL Optimizer and advanced statistics, but since the season is so far away still, that is not possible. We expect to start updating data and getting ready closer to August.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          In the meantime, feel free to explore our other tools and dashboards. Thank you for your patience and support!
        </Typography>
      </Paper>
	  <Box sx={{ mt: 3, textAlign: "center" }}>
              <Button 
                variant="text" 
                onClick={() => navigate("/dashboard")} 
                sx={{ 
                  color: '#00e676', 
                  fontWeight: 'bold', 
                  transition: 'all 0.3s ease', 
                  '&:hover': { color: '#00c853' } 
                }}
              >
                Return to Dashboard
              </Button>
            </Box>
    </Container>
  );
};

export default OptimizerUInfl;